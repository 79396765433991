import axios from "axios";
import env from "../env.json";

const ProgramService = {
	//Fetch Announcement Items
	async fetchAnnouncement() {
		return await axios
			.get(env.API_URL + "announcements?_sort=updated_at:DESC&_limit=1", {
				headers: localStorage.getItem("userAuthDetails")
					? {
							Authorization: `Bearer ${
								JSON.parse(
									localStorage.getItem("userAuthDetails")
								).jwt
							}`,
					  }
					: {},
			})
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;
				return response;
			})
			.catch(function (error) {
				return error;
			});
	},

	//Fetch Program Items
	async fetchProgramList() {
		return await axios
			.get(env.API_URL + "programs")
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;
				return response;
			})
			.catch(function (error) {
				return error;
			});
	},

	//Fetch Single Program Item
	async fetchSingleProgram(id) {
		return await axios
			.get(env.API_URL + "programs/" + id)
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;
				return response;
			})
			.catch(function (error) {
				return error;
			});
	},
};

export default ProgramService;
