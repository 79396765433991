import React, { useEffect, useState } from "react";
import Modal from "./../modal/modal";
import tick from "./../../assets/tick.svg";
import cancel from "./../../assets/cancel.svg"
import styles from "./status.module.css"; // Importing local CSS styles

const PaymentStatus = ({ action, message, error }) => {
  const [err, setErr] = useState(false)
  useEffect(()=>{if(error){
    error === 200 && setErr(true);
    error !== 200 && setErr(false)
  }
    },[error])
  // const source = () =>  if(error === 200) {return tick}else if(error !== 200) {return cancel} else return null
  return (
    <div>
      <Modal>
        <div className={styles.content}>
          {!err &&<img src={tick} alt="tick" className={styles.tickIcon} />}
          {err && <img src={cancel} alt="cancel" className={styles.tickIcon} />}
          <p className={styles.statusText}>{message}</p>
          <div className={styles.backButton} onClick={action}>
            Back
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PaymentStatus;
