import React from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../../assets/images";
import Newsletter from "./Newsletter";

import "./style.css";

const Footer = (props) => {
	const ClickHandler = () => {
		window.scrollTo(10, 0);
	};

	return (
		<div className={`wpo-ne-footer ${props.footerClass}`}>
			<Newsletter />
			<footer className="wpo-site-footer">
				<div className="wpo-upper-footer">
					<div className="container">
						<div className="row">
							<div className="col col-lg-3 col-md-6 col-12">
								<div className="widget about-widget">
									<div className="logo widget-title">
										<img src={Logo} alt="" />
									</div>
									<p>
										Unifying all Muslim students in LASU,
										Epe Campus
									</p>
									<ul>
										<li>
											<a href="https://fb.com/MSSNLASUEPE">
												<i className="ti-facebook"></i>
											</a>
										</li>
										<li>
											<a href="https://twitter.com/mssnlasuepe">
												<i className="ti-twitter-alt"></i>
											</a>
										</li>
										<li>
											<a href="https://instagram.com/mssnlasuepe">
												<i className="ti-instagram"></i>
											</a>
										</li>{" "}
									</ul>
								</div>
							</div>
							<div className="col col-lg-3 col-md-6 col-12">
								<div className="widget link-widget">
									<div className="widget-title">
										<h3>Information</h3>
									</div>
									<ul>
										<li>
											<Link
												onClick={ClickHandler}
												to="/about"
											>
												About
											</Link>
										</li>
										<li>
											<Link
												onClick={ClickHandler}
												to="/programs"
											>
												Our Programs
											</Link>
										</li>
										<li>
											<Link
												onClick={ClickHandler}
												to="/events"
											>
												Our Events
											</Link>
										</li>
										<li>
											<Link
												onClick={ClickHandler}
												to="/contact"
											>
												Contact Us
											</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className="col col-lg-2 col-md-6 col-12">
								<div className="widget link-widget">
									<div className="widget-title">
										<h3>Useful Links</h3>
									</div>
									<ul>
										<li>
											<Link
												onClick={ClickHandler}
												to="/donate"
											>
												Donate
											</Link>
										</li>
										<li>
											<Link
												onClick={ClickHandler}
												to="/ipw"
											>
												IPW
											</Link>
										</li>
										<li>
											<Link
												onClick={ClickHandler}
												to="/dawahcamp"
											>
												Dawah Camp
											</Link>
										</li>
										<li>
											<Link
												onClick={ClickHandler}
												to="/hcdp"
											>
												HCDP
											</Link>
										</li>
										<li>
											<Link
												onClick={ClickHandler}
												to="/login"
											>
												Login
											</Link>
										</li>
										<li>
											<Link
												onClick={ClickHandler}
												to="/signup"
											>
												Become a member
											</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className="col col-lg-3 offset-lg-1 col-md-6 col-12">
								<div className="widget market-widget wpo-service-link-widget">
									<div className="widget-title">
										<h3>Contact </h3>
									</div>
									<div className="contact-ft">
										<ul>
											<li>
												<i className="fi ti-location-pin"></i>
												Main Mosque, LASU Epe Campus,
												Epe, Lagos State
											</li>
											<li>
												<i className="fi flaticon-call"></i>
												+234 813-129-0362
											</li>
											<li>
												<i className="fi flaticon-envelope"></i>
												lasuepemssn@gmail.com
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="wpo-lower-footer">
					<div className="container">
						<div className="row">
							<div className="col col-xs-12">
								<p className="copyright">
									&copy; {new Date().getFullYear()} LASU EPE. All rights reserved
								</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Footer;
