import React from 'react';
import './style.css'

const NoContent = (props) => {

    return(
        Array(props.number).fill(1).map((el, i) =>
            <div className="col-md-4 mb-5" key={i}>
                <section className="card shadow-sm">
                    <div className="content-loader w-100" style={{height: 200}}/>
                    <div className="card-body bg-grey-light">
                        <div className="row">
                            <div className="col-md-8 text-md-left align-self-center text-center">
                                <div className="w-75 mx-auto mx-md-0 content-loader" style={{height: 30}}/>
                                <div className="d-flex w-75 mx-auto mx-md-0 justify-content-start mt-3">
                                    <div className="w-50 content-loader my-md-auto" style={{height: 15}}/>
                                    <div className="w-25 content-loader ml-3 my-md-auto" style={{height: 15}}/>
                                </div>
                            </div>
                            <div className="col-md-4 col-6 mx-auto mt-md-0 mt-4 my-md-auto">
                                <div className="w-100 content-loader" style={{height: 60}}/>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    )
}

export default NoContent;