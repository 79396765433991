import React from "react";
import {
	icon1,
	icon2,
	icon3,
	icon4,
	icon5,
	icon6,
} from "../../../../assets/images";

import "./style.css";

const Course = (props) => {
	const courses = [
		{
			cIcon: icon1,
			heading: "Quran Memorization",
			pra: "This program provides members an avenue to listen, recite, memorize, understand and appreciate the verses of the Glorious Qur'an. Holds every Sunday.",
		},
		{
			cIcon: icon2,
			heading: "MUBAF/MUSAF",
			pra: "The program is held every Saturday after Subh. The brothers engage in sports, majorly football, while the sisters discuss amongst themselves salient issues as it affects them.",
		},
		{
			cIcon: icon6,
			heading: "Human Capacity Development",
			pra: "This program would provide an exceptional standard for members by equipping them with necessary skill sets  that  keeps  them  relevant in the global space.",
		},
		{
			cIcon: icon4,
			heading: "College Usrah",
			pra: "It is a weekly gathering of our members where they directly learn teachings of Islam from reputable Islamic Scholars.  Areas of discussion cut across Islamic Jurisprudence (Fiqh), Explanation of some verses of the Glorious Book (Tafseer), Life of the Sahabah (Seeroh) and Hadith. ",
		},
		{
			cIcon: icon5,
			heading: "Madrasah",
			pra: "This program intends to help the members learn Arabic language and how to read the Quran the right way. Members also learn various Hadith and Fiqh.",
		},
		{
			cIcon: icon3,
			heading: "Hostel Visitation",
			pra: "A program that was put in place primarily to strengthen the bond of brotherhood and sisterhood amongst members. In addition, it also serves  as a medium for spiritual uplift.",
		},
	];
	return (
		<div className="courses-area">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="wpo-section-title">
							<h2>Our Programs</h2>
						</div>
					</div>
				</div>
				<div className="row">
					{courses.map((cource, i) => (
						<div
							className="col-lg-4 col-md-6 custom-grid col-12"
							key={i}
						>
							<div className="courses-item">
								<div className="course-icon">
									<span>
										<img src={cource.cIcon} alt="" />
									</span>
								</div>
								<div className="course-text">
									<h2>{cource.heading}</h2>
									<p>{cource.pra}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Course;
