import React from "react";
import { NormalLayout, HeaderMeta } from "../../layouts";
import PageTitle from "../../components/general/pagetitle";
import { AboutDetails, Excos } from "../../components/sections/AboutPage";
import { abimg } from "../../assets/images";

const AboutPage = () => {
	return (
		<NormalLayout footerClass={"wpo-ne-footer-2"}>
			<HeaderMeta title={"About Us"} />

			<PageTitle pageTitle={"About Us"} pagesub={"About"} />
			<AboutDetails aboutImg={abimg} />
			<Excos />
		</NormalLayout>
	);
};
export default AboutPage;
