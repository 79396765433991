import React from "react";
import { NormalLayout, HeaderMeta } from "../../layouts";
import PageTitle from "../../components/general/pagetitle";
import { CoursePage } from "../../components/sections/AcademicsPage";

const AcademicsPage = () => {
	return (
		<div>
			<NormalLayout>
				<HeaderMeta title={"Academics"} />
				<PageTitle pageTitle={"Academics"} pagesub={"Past Question"} />
				<CoursePage />
			</NormalLayout>
		</div>
	);
};

export default AcademicsPage;
