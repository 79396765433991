import React from "react";
import styled from "styled-components";
const LoaderContainer = styled.div`
width: 100vw;
height: 100vh;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
`

export default function FullPageLoader() {
    return (
        <div>
         <h1>Loading...</h1>
        </div>
    )
    }