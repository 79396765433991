import React, { useEffect } from "react";
import AllRoute from "./pages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/global.scss";
import "./assets/scss/App.css";

const App = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
		// testApi(userData);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window?.location?.pathname]);

	return (
		<div className="App" id="scrool">
			<AllRoute />

			<ToastContainer />
		</div>
	);
};

export default App;
