import React from "react";
import ContactForm from "./ContactForm";
import "./style.css";

const Contactpage = () => {
	return (
		<section className="wpo-contact-form-map section-padding">
			<div className="container">
				<div className="row">
					<div className="col-12">
						<div className="row">
							<div className="col col-lg-6 col-md-6 col-sm-12 col-12">
								<div className="contact-form">
									<h2>Get In Touch</h2>
									<ContactForm />
								</div>
							</div>
							<div className="col col-lg-6 col-md-6 col-sm-12 col-12">
								<div className="contact-map">
									<iframe
										title="map"
										src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.4646644026834!2d3.9937120141935454!3d6.589020324227592!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1039467229bddd4d%3A0x6f6fd4826e42aec5!2sLASU%20Epe%20Central%20Mosque!5e0!3m2!1sen!2sng!4v1628169183769!5m2!1sen!2sng"
										width="640"
										height="480"
									></iframe>
								</div>
							</div>
						</div>
						<div className="wpo-contact-info">
							<div className="row">
								<div className="col-lg-4 col-md-6 col-sm-12 col-12">
									<div className="info-item">
										<h2>
											Main Mosque, LASU Epe Campus, Epe,
											Lagos State
										</h2>
										<div className="info-wrap">
											<div className="info-icon">
												<i className="ti-world"></i>
											</div>
											<div className="info-text">
												<span>Address</span>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-12 col-12">
									<div className="info-item">
										<h2>info@mssnlasuepe.com.ng</h2>
										<div className="info-wrap">
											<div className="info-icon-2">
												<i className="fi flaticon-envelope"></i>
											</div>
											<div className="info-text">
												<span>Official Mail</span>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-12 col-12">
									<div className="info-item">
										<h2>+234 903-146-1460</h2>
										<div className="info-wrap">
											<div className="info-icon-3">
												<i className="ti-headphone-alt"></i>
											</div>
											<div className="info-text">
												<span>Official Phone</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contactpage;
