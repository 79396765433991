import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import SidebarWrap from "../../../general/SidebarWrap";
import EventService from "../../../../services/EventService";
import { toast } from "react-toastify";
import { thumbnail } from "../../../../assets/images";

import "./style.css";

const EventSingle = (props) => {
	const [activeTab, setActiveTab] = useState("1");
	let [event, setEvent] = React.useState({});

	useEffect(() => {
		fetchSingleEvent(props.ID);
	}, []);

	const fetchSingleEvent = (ID) => {
		EventService.fetchSingleEvent(ID).then(function (response) {
			if (response.flag) {
				setEvent(response.data);
			} else {
				toast.error(
					response && response.data && response.data.message
						? response.data.message
						: "Something went wrong. Try again"
				);
			}
		});
	};

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	return (
		<div className="wpo-event-details-area section-padding">
			<div className="container">
				<div className="row">
					<div className="col col-lg-8">
						<div className="wpo-event-item">
							<div className="wpo-event-img">
								<img
									src={
										event.image
											? event.image.formats.medium.url
											: thumbnail
									}
									alt=""
								/>
								<div className="thumb-text">
									<span>
										{new Date(event.date).getDate()}
									</span>
									<span>
										{
											months[
												new Date(event.date).getMonth()
											]
										}
									</span>
								</div>
							</div>
							<div className="wpo-event-details-text">
								<h2>{event.name}</h2>
								<p>{event.description}</p>
							</div>
							<div className="wpo-event-details-wrap">
								<div className="wpo-event-details-tab">
									<Nav tabs>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab === "1",
												})}
												onClick={() => {
													toggle("1");
												}}
											>
												Event Schedule
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab === "2",
												})}
												onClick={() => {
													toggle("2");
												}}
											>
												Map Location
											</NavLink>
										</NavItem>
									</Nav>
								</div>
								<div className="wpo-event-details-content">
									<TabContent activeTab={activeTab}>
										<TabPane tabId="1" id="Schedule">
											<p>{event.schedule_info}</p>
										</TabPane>
										<TabPane tabId="2">
											<div className="contact-map enent-map">
												<iframe
													title="Map"
													src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.4646644026834!2d3.9937120141935454!3d6.589020324227592!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1039467229bddd4d%3A0x6f6fd4826e42aec5!2sLASU%20Epe%20Central%20Mosque!5e0!3m2!1sen!2sng!4v1628169183769!5m2!1sen!2sng"
													width="640"
													height="480"
												></iframe>
											</div>
										</TabPane>
									</TabContent>
								</div>
							</div>
						</div>
					</div>
					<SidebarWrap />
				</div>
			</div>
		</div>
	);
};

export default EventSingle;
