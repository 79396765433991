import React from "react";
import { NormalLayout, HeaderMeta } from "../../layouts";
import PageTitle from "../../components/general/pagetitle";
import { Programs } from "../../components/sections/ProgramPage";

const ProgramPage = () => {
	return (
		<NormalLayout>
			<HeaderMeta title={"Programs"} />

			<PageTitle pageTitle={"Programs"} pagesub={"Programs"} />
			<Programs serviceClass={"s2"} />
		</NormalLayout>
	);
};
export default ProgramPage;
