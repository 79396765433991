import React from "react";
import { Link } from "react-router-dom";
import VideoModal from "./ModalVideo";
import "./style.css";

const AboutDetails = (props) => {
	const ClickHandler = () => {
		window.scrollTo(10, 0);
	};

	return (
		<div id="section-padding" className="wpo-about-area">
			<div className="container">
				<div className="wpo-about-wrap">
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div className="wpo-about-img-3">
								<img src={props.aboutImg} alt="" />
							</div>
						</div>
						<div className="col-lg-6 col-md-12 colsm-12">
							<div className="wpo-about-text">
								<div className="wpo-section-title">
									<h2>
										Two decades unifying all Muslim students
										in Lagos State University, Epe Campus
									</h2>
								</div>
								<p>
									The Muslim Students' Society of Nigeria,
									Lagos State University, Epe Campus is an
									institutional branch of the Lagos State Area
									Unit entrusted with the task of bringing all
									Muslims into close union and inculcating in
									them the true Islamic spirit of brotherhood
									and absolute faith in Allah as the only
									basis for the achievement of peace among
									mankind.
								</p>
								<div className="btns">
									<Link
										onClick={ClickHandler}
										to="/about"
										className="theme-btn"
										tabIndex="0"
									>
										Programs
									</Link>
									<ul>
										<li className="video-holder">
											<VideoModal />
										</li>
										<li className="video-text">
											Watch Our Video
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="wpo-about-text my-3">
							<p>
								We establish an Islamic Ummah governed by the
								principles and rules of the SHARIAH and as well
								encourage the study of the Quran as the book of
								guidance and the Arabic Language as the Lingua
								Franca of the Islamic world. We promote the
								welfare of Muslims on campus, safeguard the
								fundamental human rights and champion the cause
								of the oppressed in any community.
							</p>
							<h3 className="header mt-5">OUR CORE VALUES</h3>
							<ul className="list-group">
								<li className="list-group-item">
									<i class="far fa-arrow-alt-circle-right"></i>{" "}
									SPIRITUAL UPLIFTMENT
								</li>
								<li className="list-group-item">
									<i class="far fa-arrow-alt-circle-right"></i>{" "}
									PROMOTING ACADEMIC VIBRANCY
								</li>
								<li className="list-group-item">
									<i class="far fa-arrow-alt-circle-right"></i>{" "}
									PROFESSIONALISM
								</li>
								<li className="list-group-item">
									<i class="far fa-arrow-alt-circle-right"></i>{" "}
									MORAL RECTITUDE
								</li>
							</ul>
							<h3 className="header mt-5">OUR AFFILIATIONS</h3>
							<ul className="list-group">
								<li className="list-group-item">
									<i class="far fa-arrow-alt-circle-right"></i>{" "}
									FULL TIME UNDERGRADUATE
								</li>
								<li className="list-group-item">
									<i class="far fa-arrow-alt-circle-right"></i>{" "}
									PRE DEGREE
								</li>
								<li className="list-group-item">
									<i class="far fa-arrow-alt-circle-right"></i>{" "}
									DIPLOMA
								</li>
								<li className="list-group-item">
									<i class="far fa-arrow-alt-circle-right"></i>{" "}
									LASU MUSLIM ENGINEERING GRADUATES
									ASSOCIATION (LASUMEGA)
								</li>{" "}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutDetails;
