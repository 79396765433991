import axios from "axios";
import env from "../env.json";

const EventService = {
	//Fetch Event Items
	async fetchEventList(limit) {
		var url;
		if (limit) {
			url = `&_limit=${limit}`;
		} else {
			url = "";
		}

		return await axios
			.get(env.API_URL + "events?_sort=created_at:DESC" + url)
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;
				return response;
			})
			.catch(function (error) {
				return error;
			});
	},

	//Fetch Single Event Item
	async fetchSingleEvent(id) {
		return await axios
			.get(env.API_URL + "events/" + id)
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;
				return response;
			})
			.catch(function (error) {
				return error;
			});
	},

	//Fetch All Event Items
	async fetchAllEventList(start, limit) {
		return await axios
			.get(
				env.API_URL +
					`events?_sort=created_at:DESC&_start=${
						start || 0
					}&_limit=${limit}`
			)
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;
				return response;
			})
			.catch(function (error) {
				return error;
			});
	},

	async fetchEventCount() {
		return await axios
			.get(env.API_URL + "events/count")
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;
				return response;
			})
			.catch(function (error) {
				return error;
			});
	},
};

export default EventService;
