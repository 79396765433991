import React from "react";
import Carousel from "./Carousel";
import { line, line2, banner } from "../../../assets/images";

import "./style.css";

const DAWAHCAMPAbout = (props) => {
	// const ClickHandler = () => {
	// 	window.scrollTo(10, 0);
	// };

	// const activities = [
	// 	{
	// 		day: "Friday, 6th January, 2023",
	// 		program: "Special Jumat Service / Micro-Procession",
	// 		venue: "Epe Campus – Epe",
	// 		time: "4:15pm – 6:30pm",
	// 	},
	// 	{
	// 		day: "Saturday, 7th January, 2023",
	// 		program: "Novelty Match, Sisters Seminar & Brotherhood Night",
	// 		venue: "Epe Campus Field, PDS Hall & University Mosque",
	// 		time: "7:00am, 10:00am - 2:00pm, 8:30pm - 10:00pm",
	// 	},
	// 	{
	// 		day: "Sunday, 8th January, 2023",
	// 		program: "Formal Opening",
	// 		venue: "Epe Campus Field & Evans Hall",
	// 		time: "12:00pm – 2:00pm",
	// 	},
	// 	{
	// 		day: "Monday, 9th January, 2023",
	// 		program: "Humanitarian Visit",
	// 		venue: "Abeokuta Correctional Center",
	// 		time: "Take-Off by 9:00am",
	// 	},
	// 	{
	// 		day: "Tuesday, 10th January, 2023",
	// 		program: "Special College Usrah",
	// 		venue: "Evans Hall / College Masjid",
	// 		time: "6:00pm – 8:00pm",
	// 	},
	// 	{
	// 		day: "Wednesday, 11th January, 2023",
	// 		program: "Laylatul Mumti’a",
	// 		venue: "Campus Masjid",
	// 		time: "8:30pm - 10:00pm",
	// 	},
	// 	{
	// 		day: "Thursday, 12st January, 2023",
	// 		program: "Single Seminar",
	// 		venue: "Evans Hall / College Masjid",
	// 		time: "6:00pm - 8:00pm",
	// 	},
	// 	{
	// 		day: "Friday, 13th January, 2023",
	// 		program: "Picnic",
	// 		venue: "OOPL / FUNAAB Zoo, Abeokuta",
	// 		time: "Take-Off by 7:00am",
	// 	},
	// 	{
	// 		day: "Saturday, 14th January, 2023",
	// 		program: "Formal Closing / Professional Seminar",
	// 		venue: "Main Auditorium",
	// 		time: "10:00am - 2:00pm ",
	// 	},
	// ];

	const images = [
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228828/IPW/WhatsApp_Image_2022-02-19_at_12.47.14_AM_2_qat2es.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228828/IPW/WhatsApp_Image_2022-02-19_at_12.47.15_AM_1_fezami.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228830/IPW/WhatsApp_Image_2022-02-19_at_12.47.15_AM_7_mabn3p.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228829/IPW/WhatsApp_Image_2022-02-19_at_12.47.15_AM_6_ig5ttd.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228829/IPW/WhatsApp_Image_2022-02-19_at_12.47.15_AM_5_fobtps.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228829/IPW/WhatsApp_Image_2022-02-19_at_12.47.15_AM_4_jiu6di.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228829/IPW/WhatsApp_Image_2022-02-19_at_12.47.15_AM_3_t7ijd4.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228828/IPW/WhatsApp_Image_2022-02-19_at_12.47.14_AM_xwfpkm.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228828/IPW/WhatsApp_Image_2022-02-19_at_12.47.14_AM_3_n0wu2k.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228828/IPW/WhatsApp_Image_2022-02-19_at_12.47.14_AM_1_gslsjd.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228829/IPW/WhatsApp_Image_2022-02-19_at_12.47.15_AM_2_rg3sve.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228827/IPW/WhatsApp_Image_2022-02-19_at_12.47.12_AM_b7hffp.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228826/IPW/WhatsApp_Image_2022-02-19_at_12.47.11_AM_1_zk1idv.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228826/IPW/WhatsApp_Image_2022-02-19_at_12.47.15_AM_14_hxseuv.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228826/IPW/WhatsApp_Image_2022-02-19_at_12.47.15_AM_h9e1io.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228827/IPW/WhatsApp_Image_2022-02-19_at_12.47.11_AM_fm9wi8.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228825/IPW/WhatsApp_Image_2022-02-19_at_12.47.15_AM_13_szupwr.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228825/IPW/WhatsApp_Image_2022-02-19_at_12.47.15_AM_8_yv22uh.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228825/IPW/WhatsApp_Image_2022-02-19_at_12.47.15_AM_10_umh1c8.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228825/IPW/WhatsApp_Image_2022-02-19_at_12.47.15_AM_11_g48ssu.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228825/IPW/WhatsApp_Image_2022-02-19_at_12.47.15_AM_9_oifsze.jpg",
		},
		{
			src: "https://res.cloudinary.com/dfhteb3xs/image/upload/v1645228825/IPW/WhatsApp_Image_2022-02-19_at_12.47.15_AM_12_iouses.jpg",
		},
	];

	return (
		<>
			<div className="mb-4">
				<div className="container">
					<div className="row">
						<div className="col-lg">
							<div className="support-text my-6">
								<h1 className="text-center">What is Dawah camp?</h1>
								<p>
                                Dawah camp is an annual camping activity specially staged 
                                for all members and young vibrant Muslims from other 
                                institutions to enhance their spiritual consciousness 
                                through heart fulfilling and nourishing lectures, contemporary
                                discussion on the present status of the Muslims globally, 
                                Qur'an competition and several other exciting events. our previous dawah 
									{/* <i>
										<strong>“The neglected succor”</strong>
									</i>{" "}
									,{" "}
									<i>
										<strong>“The Enlightened”</strong>
									</i>
									, and{" "}
								
									; it achieved the earlier stated objective
									of the week-long program. */}
								</p>
								<p>
									Dawah creates an avenue to have a wider
									reach to members calling and explaining to
									them the noble practices of our religion. It
									is a da'wah focused week strategically aimed
									at equipping our members with genuine
									Islamic teachings, building in them the
									culture of humanity through humanitarian
									services, solidifying their professional
									strength, getting them socially aware about
									the necessity of being informed and
									inspiring them to be the best version of
									themselves.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="bg-grey">
				<div className="container">
					<div className="row">
						<div className="col-lg">
							<div className="support-text">
								<p className="text-head">
									Vicegerency: <br /> <span >Living Up the Mandate</span>
								</p>

								<div className="row">
									<div className="col-lg-8 col-md-8 col-sm-12">
										<p>
                                        It has been noticed that youths on campus 
                                        don't know their status as humans i.e one 
                                        of the creation of Allah(SWT) in the sense 
                                        that virtually all muslims now engage in 
                                        one major sin or the other and those who 
                                        try to abstain from these sins don't tend 
                                        to spread the word of Allah(Da'wah). With 
                                        this theme, we will aim to remind the muslim 
                                        students about them being the vicegerent
                                        (ambassador or appointees) of Allah on earth. 
										<br /> 
										<br />
                                        As a vicegerent, we don't have any other 
                                        primary role than to follow Allah's injunctions 
                                        as stated in the Hadith and Qur'an.
											{/* <br />
											<br />
											The existence of humans started from
											the first human to be created, Adam.
											He was created and he lived in
											paradise until Shaytan deceived him
											to go against the command of Allah,
											then he was then commanded to live
											on earth as a mortal soul. However,
											he sought Allah's forgiveness and he
											was forgiven and then, Allah assured
											him his guidance would be sent to
											him and those that follow his
											command. Shaytan shall not have
											power over them and paradise is
											surely their abode. Hence, it is not
											possible for a person who
											understands his/her purpose as a
											human to be over powered by shaytan. */}
										</p>
									</div>
									<div className="col-lg-4 col-md-4 col-sm-12 py-12">
										<div className="wpo-about-img-3">
											<img
												className="img-fluid rounded"
												src={banner}
												alt=""
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <div className="spacing">
				<div className="container">
					<div className="">
						<div className="">
							<div className="support-text">
								<p className="text-head my-3">Activity flow</p>
								<img className="line-2" src={line2} alt="" />
								<div className="row d-flex justify-content-between align-content-center flex-wrap">
									{activities.map(
										({ day, program, venue, time }, i) => (
											<div className="col-lg-4 col-md-4 col-sm-12">
												<p>
													<strong className="text-dark">
														Day {i + 1}:{" "}
													</strong>
													{day}
												</p>
												<div>
													<p>
														Program: {program}{" "}
														<br />
														Venue: {venue} <br />
														Time: {time}
													</p>
												</div>
											</div>
										)
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}

			<div className="bg-grey">
				<div className="container">
					<div className="support-text">
						<p className="text-head my-3">Our Request</p>
						<div>
							<p>
								The atrocities happening in the society is clear
								to the blind and this is our way of reducing it
								by exposing students to the emerging
								technologies, understanding current digital
								realities and provide them with the best ways to
								navigate and utilize the opportunities the comes
								with it without contravening limits.
								<br />
								We are planning to make the students have sweet
								experience while these lessons are impacted in
								them. This is only possible through your help
								and support.
								<br />
								We hereby seek your support both morally and
								financially, to make the plan of this program
								effectively and efficiently executed.
								<br />
								While we await your assured positive approval to
								our request on financial and moral support, We
								ask Allah to reward you abundantly and amass you
								beneficial wealth.
								<h3 className="py-3">
									<strong>JazakumuLlahu Khaeran</strong>
								</h3>
							</p>
							<div></div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DAWAHCAMPAbout;
