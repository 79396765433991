import React from "react";
import { NormalLayout, HeaderMeta } from "../../layouts";
import {
	Hero,
	About,
	Course,
	Pilars,
	Support,
} from "../../components/sections/HomePage";
import { abimg, hero1 } from "../../assets/images";

const HomePage = () => {
	return (
		<NormalLayout footerClass="0">
			<HeaderMeta />

			<Hero HeroStyleClass={"hero-style-4"} heroImg={hero1} />
			<About aboutImg={abimg} />
			<Course />
			<Pilars />
			<Support />
		</NormalLayout>
	);
};
export default HomePage;
