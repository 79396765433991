import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import ProgramService from "../../../../services/ProgramService";
import { toast } from "react-toastify";
import NoContent from "../../../general/NoContent";
import MarkdownView from "react-showdown";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "./style.css";

const ProgramSingle = (props) => {
	let [program, setProgram] = React.useState({});
	let [isLoading, setIsLoading] = React.useState(true);

	useEffect(() => {
		fetchSingleProgram(props.ID);
	}, []);

	const fetchSingleProgram = (ID) => {
		ProgramService.fetchSingleProgram(ID).then(function (response) {
			if (response.flag) {
				setIsLoading(false);
				setProgram(response.data);
			} else {
				toast.error(
					response && response.data && response.data.message
						? response.data.message
						: "Something went wrong. Try again"
				);
			}
		});
	};

	return (
		<section className="service-single-section section-padding">
			<div className="container">
				<div className="row">
					<div className="col col-lg-8 col-md-12 col-12">
						<div className="service-single-content">
							<h2>{program.name}</h2>
							{isLoading && (
								<div className="row my-4">
									<NoContent number={5} />
								</div>
							)}

							{!isLoading && program.sub_programs && (
								<>
									{program.sub_programs.length === 0 ? (
										<h3 className="py-5 text-center">
											{" "}
											No data to show{" "}
										</h3>
									) : (
										<>
											{program.sub_programs.map(
												(item, i) => (
													<Card
														id={item.id}
														className="my-5"
														type={i}
													>
														<Card.Header as="h5">
															{item.name}
														</Card.Header>
														<Card.Body>
															<Card.Text>
																<MarkdownView
																	markdown={
																		item.description
																	}
																	options={{
																		tables: true,
																		emoji: true,
																	}}
																/>
															</Card.Text>
														</Card.Body>
													</Card>
												)
											)}
										</>
									)}
								</>
							)}
						</div>
					</div>

					<div className="col col-lg-4 col-md-12 col-12">
						<div className="service-sidebar">
							<div className="widget service-list-widget">
								{isLoading && (
									<div className="row my-4">
										<NoContent number={3} />
									</div>
								)}

								{!isLoading && program.sub_programs && (
									<>
										{program.sub_programs.length === 0 ? (
											<h3 className="py-5 text-center">
												{" "}
												No data to show{" "}
											</h3>
										) : (
											<>
												<h3>All {program.name}</h3>
												<ul>
													{program.sub_programs.map(
														(item, i) => (
															<AnchorLink
																offset="100"
																href={
																	"#" +
																	item.id
																}
															>
																{item.name}
															</AnchorLink>
														)
													)}
												</ul>
											</>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ProgramSingle;
