import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ProjectService from "../../../../services/ProjectService";
import { toast } from "react-toastify";
import NoContent from "../../../general/NoContent";
import { thumbnail } from "../../../../assets/images";

import "./style.css";

const Projects = (props) => {
	let [projects, setProjects] = React.useState([]);
	let [isLoading, setIsLoading] = React.useState(true);

	useEffect(() => {
		fetchAllProjects();
	}, []);

	const ClickHandler = () => {
		window.scrollTo(10, 0);
	};

	const fetchAllProjects = () => {
		ProjectService.fetchProjectList().then(function (response) {
			if (response.flag) {
				setIsLoading(false);
				setProjects(response.data);
			} else {
				toast.error(
					response && response.data && response.data.message
						? response.data.message
						: "Something went wrong. Try again"
				);
			}
		});
	};

	return (
		<div className={`service-area-2 ${props.serviceClass}`}>
			<div className="container">
				{isLoading && (
					<div className="row my-4">
						<NoContent number={3} />
					</div>
				)}

				{!isLoading && (
					<>
						<div className="service-wrap">
							<div className="row">
								{projects.length === 0 ? (
									<h3 className="py-5 text-center">
										{" "}
										No data to show{" "}
									</h3>
								) : (
									<>
										{projects.map((item, i) => (
											<div
												className="col-lg-4 col-md-6 col-sm-12 custom-grid col-12"
												key={i}
											>
												<div className="service-single-item">
													<div className="service-single-img">
														<img
															src={
																item.image
																	? item.image
																			.url
																	: thumbnail
															}
															alt=""
														/>
													</div>
													<div className="service-text">
														<h2>
															<Link
																onClick={
																	ClickHandler
																}
																to={
																	"project/" +
																	item.id
																}
															>
																{item.name}
															</Link>
														</h2>
													</div>
												</div>
											</div>
										))}
									</>
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default Projects;
