import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import EventService from "../../../../services/EventService";
import { toast } from "react-toastify";
import NoContent from "../../../general/NoContent";
import { thumbnail } from "../../../../assets/images";

import "./style.css";

const EventSection2 = (props) => {
	let [events, setEvents] = useState([]);
	let [isLoading, setIsLoading] = useState(true);
	const [pageCount, setPageCount] = useState(0);
	const [EventCount, setEventCount] = useState(0);
	const [selected, setSelected] = useState(null);

	let limit = 4;

	useEffect(() => {
		fetchEventCount();
		fetchAllEvents();
	}, [limit]);

	const fetchEventCount = async () => {
		const count = await EventService.fetchEventCount();
		const total = count.data;
		setEventCount(total);
		setPageCount(Math.ceil(total / limit));
	};

	const fetchAllEvents = () => {
		EventService.fetchAllEventList(0, limit).then(function (response) {
			if (response.flag) {
				setIsLoading(false);
				setEvents(response.data);
			} else {
				toast.error(
					response && response.data && response.data.message
						? response.data.message
						: "Something went wrong. Try again"
				);
			}
		});
	};

	const fetchEvents = async (currentPage) => {
		setIsLoading(true);
		EventService.fetchAllEventList(currentPage, limit).then(function (
			response
		) {
			if (response.flag) {
				setEvents(response.data);
				setIsLoading(false);
			} else {
				toast.error(
					response && response.data && response.data.message
						? response.data.message
						: "Something went wrong. Try again"
				);
			}
		});
	};

	const handlePageClick = async (data) => {
		let selected = data.selected;
		setSelected(selected);
		let offset = Math.ceil(selected * limit);
		await fetchEvents(offset);
		// scroll to the top
		window.scrollTo(10, 0);
	};

	const ClickHandler = () => {
		window.scrollTo(10, 0);
	};

	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	const mystyle = {
		height: "290px",
		width: "240px",
	};

	function DisplayCurrentTime(passedDate) {
		var date = new Date(passedDate);
		var hours =
			date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
		var am_pm = date.getHours() >= 12 ? "PM" : "AM";
		hours = hours < 10 ? "0" + hours : hours;
		var minutes =
			date.getMinutes() < 10
				? "0" + date.getMinutes()
				: date.getMinutes();
		const time = hours + ":" + minutes + " " + am_pm;
		return time;
	}

	return (
		<>
			<div
				className={`wpo-event-area section-padding  ${props.EventClass}`}
			>
				<div className="container">
					{isLoading && (
						<div className="row my-4">
							<NoContent number={3} />
						</div>
					)}

					{!isLoading && (
						<>
							<div className="row">
								{events.length === 0 ? (
									<h3 className="py-5 text-center">
										{" "}
										No data to show{" "}
									</h3>
								) : (
									<>
										{events.map((item, i) => (
											<div
												className="col-lg-6 col-sm-12 col-12 custom-grid"
												key={i}
											>
												<div className="wpo-event-item">
													<div className="wpo-event-img">
														<img
															style={mystyle}
															src={
																item.image
																	? item.image
																			.formats
																			.medium
																			.url
																	: thumbnail
															}
															alt=""
														/>
														<div className="thumb-text">
															<span>
																{new Date(
																	item.date
																).getDate()}
															</span>
															<span>
																{
																	months[
																		new Date(
																			item.date
																		).getMonth()
																	]
																}
															</span>
														</div>
													</div>
													<div className="wpo-event-text">
														<h2>{item.name}</h2>
														<ul>
															<li>
																<i
																	className="fa fa-clock-o"
																	aria-hidden="true"
																></i>
																{DisplayCurrentTime(
																	item.date
																)}
															</li>
															<li>
																<i className="fa fa-map-marker"></i>
																{item.location}
															</li>
														</ul>
														<p>
															{item.description
																.length > 80
																? item.description.substr(
																		0,
																		80
																  ) + " ..."
																: item.description}
														</p>
														<Link
															onClick={
																ClickHandler
															}
															to={
																"event/" +
																item.id
															}
														>
															Learn More
														</Link>
													</div>
												</div>
											</div>
										))}
									</>
								)}
							</div>
							{EventCount >= 4 && (
								<ReactPaginate
									previousLabel={"Previous"}
									nextLabel={"Next"}
									breakLabel={"..."}
									pageCount={pageCount}
									marginPagesDisplayed={2}
									pageRangeDisplayed={3}
									onPageChange={handlePageClick}
									containerClassName={
										"pagination justify-content-center"
									}
									pageClassName={"page-item"}
									pageLinkClassName={"page-link"}
									previousClassName={"page-item"}
									previousLinkClassName={"page-link"}
									nextClassName={"page-item"}
									nextLinkClassName={"page-link"}
									breakClassName={"page-item"}
									breakLinkClassName={"page-link"}
									activeClassName={"active"}
									forcePage={selected}
								/>
							)}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default EventSection2;
