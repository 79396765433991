import React, { useEffect } from "react";
import ExcoService from "../../../../services/ExcoService";
import { toast } from "react-toastify";
import NoContent from "../../../general/NoContent";
import MarkdownView from "react-showdown";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { thumbnail } from "../../../../assets/images";

import "./style.css";

const Excos = () => {
	let [excos, setExcos] = React.useState([]);
	let [detail, setDetail] = React.useState([]);
	let [isLoading, setIsLoading] = React.useState(true);
	let [modal, setModal] = React.useState(false);

	const toggle = () => {
		setModal(!modal);
	};

	const toggler = (id) => {
		let result = excos.filter((exco) => exco.id === id);
		if (result[0] && result[0].bio.length > 10) {
			setDetail(result[0]);
			setModal(!modal);
		}
	};

	useEffect(() => {
		fetchAllExcos();
	}, []);

	const fetchAllExcos = () => {
		ExcoService.fetchExcosList().then(function (response) {
			if (response.flag) {
				setIsLoading(false);
				setExcos(response.data);
			} else {
				toast.error(
					response && response.data && response.data.message
						? response.data.message
						: "Something went wrong. Try again"
				);
			}
		});
	};

	return (
		<div class="team text-center section-paddin">
			<div class="container">
				<div class="header mt-3">
					<h1>Meet the Current Executives </h1>
				</div>
				{isLoading && (
					<div className="row my-4">
						<NoContent number={6} />
					</div>
				)}

				{!isLoading && excos && (
					<>
						<div className="row">
							{excos.length === 0 ? (
								<h3 className="py-5 text-center">
									{" "}
									No data to show{" "}
								</h3>
							) : (
								<>
									{excos.map((excomembers, i) => (
										<div
											class="col-md-6 col-lg-3 my-3"
											onClick={() =>
												toggler(excomembers.id)
											}
											key={i}
										>
											<div class="contain img-block mb-3">
												<div class="contains">
													<img
														src={
															excomembers.image
																? excomembers
																		.image
																		.url
																: thumbnail
														}
														alt={excomembers.name}
														class="image rounded z-depth-1-half img-fluid img-Thumbnail h-100"
													></img>
													{excomembers.bio &&
														excomembers.bio.length >
															10 && (
															<div class="overlay">
																Click to View
																Bio
															</div>
														)}
												</div>
												<div class="content mt-2 pointer">
													<h4>{excomembers.name}</h4>
													<p class="text-muted ">
														{excomembers.title}
													</p>
												</div>
											</div>
										</div>
									))}
								</>
							)}
						</div>
					</>
				)}

				{detail && (
					<Modal
						isOpen={modal}
						zIndex={20000000}
						toggle={toggle}
						className="modal-dialog modal-dialog-centered modal-dialog-scrollable"
					>
						<ModalHeader toggle={toggle}>
							{detail.name}'s Biography
						</ModalHeader>
						<ModalBody>
							<MarkdownView
								markdown={detail.bio}
								options={{ tables: true, emoji: true }}
							/>
						</ModalBody>
						<ModalFooter>
							<Button color="secondary" onClick={toggle}>
								Close
							</Button>
						</ModalFooter>
					</Modal>
				)}
			</div>
		</div>
	);
};

export default Excos;
