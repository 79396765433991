import React, { useEffect, useState } from "react";
import { HeaderMeta } from "../../layouts";
import { toast } from "react-toastify";
import PaymentService from "../../services/PaymentService";
import { Player } from "@lottiefiles/react-lottie-player";
import { LoadingIndicator, NotFound, Success } from "../../assets/images";

export default function PaymentVerification(props) {
	const [state, setState] = useState({
		isLoader: false,
		failure: false,
	});

	useEffect(() => {
		let query = new URLSearchParams(props.location.search);
		if (query.get("status") === "successful" && query.get("tx_ref")) {
			setState((prev) => ({ ...prev, isLoader: true }));
			PaymentService.verifyDonate({
				tx_ref: query.get("tx_ref"),
				transaction_id: query.get("transaction_id"),
			}).then(function (response) {
				if (response.flag) {
					setState((prev) => ({ ...prev, isLoader: false, failure: false }));
					setTimeout(function () {
						window.location.replace("/");
					}, 5000);
				} else {
					setState((prev) => ({ ...prev, failure: true, isLoader: false }));
					toast.error("Something went wrong!");
					toast.error("Retrying...");
					setTimeout(function () {
						window.location.reload();
					}, 2000);
				}
			});
		} else {
			setState((prev) => ({ ...prev, isLoader: false, failure: true }));
			if (query.get("status") === "cancelled") {
				toast.error("Payment Cancelled");
			} else {
				toast.error(
					"Not a valid Transaction reference for verification"
				);
			}
			window.location.replace("/donate");
		}
	}, []);

	const { isLoader, failure } = state;

	return (
		<>
			<HeaderMeta />

			{!failure ? (
				isLoader ? (
					<div className="row text-center py-5">
						<div className="col-md-6 col-11 px-5 mx-auto">
							<img
								src={LoadingIndicator}
								width={320}
								alt="loading"
							/>
							<h3
								className="text-theme-primary font-weight-bold"
								style={{ marginTop: -30 }}
							>
								Verifying your Payment
							</h3>
						</div>
					</div>
				) : (
					<div className="row text-center py-5">
						<div className="col-md-6 col-11 px-5 bg-success-light mx-auto">
							<Player
								src={Success}
								style={{
									width: 250,
									height: 250,
									margin: "0 auto",
								}}
								loop
								autoplay
							/>
							<div className="px-md-5 mb-5">
								<h3 className="font-weight-bold my-3">
									Voila! Your Payment is successfully
									verified.
								</h3>
								<h6 className="mb-4">
									Please wait, you will be redirected in 5
									seconds...
								</h6>
							</div>
						</div>
					</div>
				)
			) : (
				<div className="row text-md-left text-center py-5 mb-5">
					<div className="col-md-8 col-11 p-md-5 py-5 bg-primary-light mx-auto">
						<div className="d-md-flex justify-md-content-between">
							<Player
								src={NotFound}
								style={{ width: 300, margin: "0 auto" }}
								loop
								autoplay
							/>
							<div className="my-auto">
								<h2
									className={
										"font-weight-bold mb-3 text-danger"
									}
								>
									Sorry, we can't verify your payment
								</h2>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
