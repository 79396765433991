import Index from "../../pages/Dashboard/index.js";
import Profile from "../../pages/Dashboard/Profile.js";
import Tables from "../../pages/Dashboard/Tables.js";

var routes = [
	{
		path: "/index",
		name: "Home",
		icon: "ni ni-tv-2 text-primary mr-3",
		component: Index,
		// layout: "/dashboard",
	},
	{
		path: "/user-profile",
		name: "Profile",
		icon: "ni ni-single-02 text-yellow mr-3",
		component: Profile,
		// layout: "/dashboard",
	},
	{
		path: "/payment-history",
		name: "Payment History",
		icon: "ni ni-planet text-blue mr-3",
		component: Tables,
		// layout: "/dashboard",
	},
];
export default routes;
