import React from "react";
import { useState } from "react";
import cancel from "./assets/cancel.svg";
import VerifyId from "./components/verifyID/verifyID";
import PaymentStatus from "./components/paymentstatus/status";
import styles from "./App.module.css"; // Importing local CSS styles
import TicketDetails from "./components/userReceipt/receipt";
import axios from "axios";
import { toast } from "react-toastify";

const HomePage = () => {
	const token = JSON.parse(localStorage.getItem("token"));
  const [showFirstOptions, setFirstShowOptions] = useState(false);
  const [showSecondOptions, setSecondShowOptions] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [type, setType] = useState("");
  const [valueText, setValueText] = useState("");
  const [showSecOverlay, setShowSecOverlay] = useState(false);
  const [message, setMessage] = useState("")
  const [error, setError] = useState(200)
  const [Mod, setMod ] = useState(false)
  const [ticketDetails, setTicketDetails] = useState({
    purchasedBy:"",
    email: "",
    isTicketUsed: Boolean,
    usedAt: "",
    customticketID: "",
    updatedAt: ""
},
)

  const backToId = () => {
    setShowSecOverlay(false);
    setShowOverlay(true);
    setMod(false)
  };

  const modalShow = ()=> {
    setShowSecOverlay(true);
    setShowOverlay(true);
    setMod(true)
    setShowSecOverlay(false)
  }
  const handleShowModal = async () => {
    setShowSecOverlay(true);
    setShowOverlay(false);
    setMod(false)
    const data = {
      ticketName: type,
      ticketNo: valueText,
    };

    await axios
      .post(
        "https://main-website-backend-lm0y.onrender.com/buy-tickets/verify-ticket",
        // "http://localhost:3000/buy-tickets/verify-ticket",
        data,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      )
      .then((response) => {
        toast.success(response.data?.message);
        setTicketDetails(response.data?.ticketDetails)
        setError(response?.status);
      })
      .catch((error) => {
        toast.error(error?.response?.data);
        setMessage(error?.response?.data?.message)
        setError(error?.response?.status);
      });
  };

  const handleDecider = (value) => {
    setType(value);
    setShowOverlay(true);
    setShowSecOverlay(false);
  };

  const handleShowFirstOptions = () => {
    setFirstShowOptions(true);
  };

  const handleShowSecondOptions = () => {
    setSecondShowOptions(true);
  };
// set id
  const handleTextValue = (e) => {
    const value = e.target.value;
    setValueText(value);
  };
  // back to home
  const handleFirstOverlay = () => {
    setShowOverlay(true);
  };
  return (
    <div>
      <div className={styles.header}>
        <h1 className={styles.head}>Verify Ticket</h1>
        <p className={styles.subHead}>
          home / <span className={styles.verify}> verification</span>
        </p>
      </div>
      <div className={styles.body}>
        <p className={styles.ver}>verify</p>
        <div className={styles.select}>
          <div className={styles.tId}>
            <div
              onClick={handleShowFirstOptions}
              className={styles.cursorPointer}
            >
              ID Verification
            </div>

            {showFirstOptions && (
              <div className={styles.selection}>
                <img
                  src={cancel}
                  alt="cancel"
                  className={styles.cancel}
                  onClick={handleShowFirstOptions}
                />
                <ul>
                  <div
                    className={styles.iftaar}
                    onClick={() => handleDecider("Iftar")}
                  >
                    Iftaar
                  </div>
                  <hr />
                  <div
                    className={styles.sahoor}
                    onClick={() => handleDecider("Sahur Ticket")}
                  >
                    Sahoor
                  </div>
                </ul>
              </div>
            )}
          </div>
          <hr />
          <div className={styles.tId}>
            <div
              onClick={handleShowSecondOptions}
              className={styles.cursorPointer}
            >
              Scan QR Code
            </div>

            {showSecondOptions && (
              <div className={styles.selection}>
                <img
                  src={cancel}
                  alt="cancel"
                  className={styles.cancel}
                  onClick={handleShowSecondOptions}
                />
                <ul>
                  <li className={styles.iftaar}>iftaar</li>
                  <li className={styles.sahoor}>sahoor</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      {showOverlay && (
        <VerifyId
          showModal={handleShowModal}
          textValue={handleTextValue}
          cancelDisplay={handleFirstOverlay}
        />
      )}
      {showSecOverlay && error === 200 && <TicketDetails error={error} ticketDetails={ticketDetails} modalShow={modalShow} backToId={backToId} message={message} modShow={Mod}/> }
      {showSecOverlay && error !== 200 &&  <PaymentStatus action={backToId} message={message} error={error}/>}
    </div>
  );
};

export default HomePage;
