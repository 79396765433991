import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withRouter, useLocation } from "react-router-dom";
import AuthService from "../../services/AuthService";
import { Player } from "@lottiefiles/react-lottie-player";
import { LoadingIndicator } from "../../assets/images";
import { HeaderMeta } from "../../layouts";
import "../ForgotPassword/style.scss";


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PasswordResetPage = (props) => {
  let query = useQuery();

  useEffect(() => {
    if (!query.get("token")) {
      props.history.push("/404");
    }
  }, [props.history, query]);

  const [value, setValue] = useState({
    password: "",
    confirm_password: "",
    loader: false,
  });

  const changeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
    validator.showMessages();
  };

  const [validator] = React.useState(
    new SimpleReactValidator({
      className: "text-danger",
    })
  );

  const submitForm = (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      setValue({ ...value, loader: true });
      AuthService.updatePass({
        token: query.get("token"),
        password: value.password,
        passwordConfirmation: value.confirm_password,
      }).then(function (response) {
        if (response) {
          setValue({
            password: "",
            confirm_password: "",
            loader: false,
          });
          toast.success("Password Reset Successfully");
          props.history.push("/login");
        } else {
          setValue({
            password: "",
            confirm_password: "",
            loader: false,
          });
          if (response.id === "Auth.form.error.code.provide") {
            toast.error("Invalid Password Reset Token");
          } else {
            toast.error(
              response.message
                ? response.message
                : "Something went wrong. Try again"
            );
          }
          props.history.push("/");
        }
      });
    } else {
      validator.showMessages();
      toast.error("Empty field is not allowed!");
    }
  };

  return (
    <>
      <HeaderMeta title={"Password Reset"} />
      <Grid className="loginWrapper">
        <Grid className="loginForm">
          <h2>Reset your account password</h2>
          <p>Fill in your new password</p>
          <form onSubmit={submitForm}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  className="inputOutline"
                  fullWidth
                  placeholder="New Password"
                  value={value.password}
                  variant="outlined"
                  name="password"
                  type="password"
                  label="New Password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                />
                {validator.message(
                  "password",
                  value.password,
                  "required|min:8"
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="inputOutline"
                  fullWidth
                  placeholder="Confirm New Password"
                  value={value.confirm_password}
                  variant="outlined"
                  name="confirm_password"
                  type="password"
                  label="Confirm New Password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                />
                {validator.message(
                  "confirm_password",
                  value.confirm_password,
                  `in:${value.password}`,
                  { messages: { in: "Password mismatch!" } }
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid className="formFooter">
                  <Button
                    fullWidth
                    className="cBtn cBtnLarge cBtnTheme"
                    type="submit"
                  >
                    {value.loader ? (
                      <span className="d-inline-flex">
                        Resetting Password...{" "}
                        <Player
                          loop="true"
                          src={LoadingIndicator}
                          style={{
                            width: 18,
                            marginLeft: 5,
                          }}
                          autoplay="true"
                        />
                      </span>
                    ) : (
                      "Reset Password"
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(PasswordResetPage);
