import React, { useState } from "react";
import Courses from "./Courses";
import SearchBar from "./SearchBar";
const CoursePage = () => {
  const [search, setSearch] = useState("");
  const courses = [
    {
      id: 1,
      course: "Fundamentals of Magnetism",
      courseCode: "ECE-201",
      year: "21/22",
      color: "#238B15",
    },
    {
      id: 2,
      course: "Engines on Machines",
      courseCode: " MEE-305",
      year: "17/18",
      color: "#525252",
    },
    {
      id: 3,
      course: "Fundamentals of Thermodynamics ",
      courseCode: "CPE-201",
      year: "19/20",
      color: "#238B15",
    },
    {
      id: 4,
      course: "Kinetics of a Moving Plane",
      courseCode: "ASE-201",
      year: "20/21",
      color: "#9E0909",
    },
    {
      id: 5,
      course: "Data Structure and Algorithm",
      courseCode: "ECE-311",
      year: "19/20",
      color: "#01A6A1",
    },
    {
      id: 6,
      course: "Data Structure and Algorithm",
      courseCode: "ECE-311",
      year: "19/20",
      color: "#01A6A1",
    },
  ];

  const filterCourses = courses.filter((course) => {
    return (
      course.course
        .toString()
        .toLowerCase()
        .includes(search.toLowerCase())
		 ||
      course.courseCode
        .toString()
        .toLowerCase()
        .includes(search.toLowerCase())
    );
  });
  return (
    <div>
      <SearchBar handleChange={(e) => setSearch(e.target.value)} />
      <Courses filterCourses={filterCourses}/>
    </div>
  );
};

export default CoursePage;
