import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import AuthService from "../../services/AuthService";
import QuranLoader from "../../layouts/DashboardLayout/Footers/QuranLoader";
import UserHeader from "../../layouts/DashboardLayout/Headers/UserHeader";
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	FormGroup,
	Form,
	Input,
	Container,
	Row,
	Col,
} from "reactstrap";
// core components

const Profile = (props) => {
	const [isLoading, setIsLoading] = React.useState(true);

	const image = `https://ui-avatars.com/api/?background=5cb85c&color=FFF&bold=true&size=800&name=${props.user.first_name}+${props.user.last_name}`;

	const [value, setValue] = useState({
		hostel_address: props.user.hostel_address,
		level: props.user.level,
		phone: props.user.phone,
	});

	let [quran, setQuran] = useState(null);

	useEffect(() => {
		quranVerse();
	}, []);

	const quranVerse = () => {
		const ayah = Math.floor(Math.random() * 6236) + 1;
		axios({
			method: "GET",
			url: `https://api.alquran.cloud/ayah/${ayah}/en.asad`,
		})
			.then((response) => {
				setIsLoading(false);
				setQuran(response.data.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const changeHandler = (e) => {
		setValue({ ...value, [e.target.name]: e.target.value });
	};

	const updateProfile = (e) => {
		AuthService.updateProfile(value).then(function (response) {
			if (response.flag) {
				toast.success("User Profile Updated Sucessful");
				setTimeout(function () {
					window.location.reload();
				}, 2000);
			} else {
				toast.error(
					response
						? response.message
						: "Unable to Update Profile Something went wrong. Try again"
				);
			}
		});
	};

	return (
		<>
			<UserHeader />
			{/* Page content */}
			<Container className="mt--7" fluid>
				<Row>
					<Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
						<Card className="card-profile shadow">
							<Row className="justify-content-center">
								<Col className="order-lg-2" lg="3">
									<div className="card-profile-image">
										<img
											alt="..."
											className="rounded-circle"
											src={image}
										/>
									</div>
								</Col>
							</Row>
							<CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></CardHeader>
							<CardBody className="pt-0 pt-md-4">
								<Row>
									<div className="col">
										<div className="card-profile-stats d-flex justify-content-center mt-md-5"></div>
									</div>
								</Row>
								<div className="text-center">
									<h3>
										{props.user.first_name +
											"  " +
											props.user.last_name}
										<span className="font-weight-light">
											, {props.user.level} Level
										</span>
									</h3>
									<div className="h5 font-weight-300">
										<i className="ni location_pin mr-2" />
										{props.user.hostel_address}
									</div>
									<div className="h5 mt-4">
										<i className="ni business_briefcase-24 mr-2" />
										{props.user.department},{" "}
										{props.user.faculty}
									</div>
									<div>
										<i className="ni education_hat mr-2" />
										Lagos State University
									</div>
									<hr className="my-4" />
									{isLoading && <QuranLoader />}
									{!isLoading && (
										<>
											<p>{quran && quran.text}</p>
											<p className="text-center">
												{quran &&
													quran.surah
														.englishName}{" "}
												: {quran && quran.numberInSurah}
											</p>
										</>
									)}
								</div>
							</CardBody>
						</Card>
					</Col>
					<Col className="order-xl-1" xl="8">
						<Card className="bg-secondary shadow">
							<CardHeader className="bg-white border-0">
								<Row className="align-items-center">
									<Col xs="8">
										<h3 className="mb-0">My account</h3>
									</Col>
								</Row>
							</CardHeader>
							<CardBody>
								<Form>
									<h6 className="heading-small text-muted mb-4">
										Member Information
									</h6>
									<div className="pl-lg-4">
										<Row>
											<Col lg="6">
												<FormGroup>
													<label
														className="form-control-label"
														htmlFor="input-first-name"
													>
														Faculty
													</label>
													<Input
														className="form-control-alternative"
														defaultValue={
															props.user.faculty
														}
														id="input-phone"
														placeholder="Faculty"
														type="text"
														disabled
													/>
												</FormGroup>
											</Col>
											<Col lg="6">
												<FormGroup>
													<label
														className="form-control-label"
														htmlFor="input-email"
													>
														Department
													</label>
													<Input
														className="form-control-alternative"
														id="input-email"
														defaultValue={
															props.user
																.department
														}
														placeholder="Department"
														type="text"
														disabled
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col lg="6">
												<FormGroup>
													<label
														className="form-control-label"
														htmlFor="input-last-name"
													>
														Level
													</label>
													<Input
														type="select"
														name="level"
														id="exampleSelect"
														className="form-control-alternative"
														onChange={(e) =>
															changeHandler(e)
														}
														required
													>
														<option
															value={
																props.user.level
															}
														>
															{props.user.level}{" "}
															Level
														</option>
														<option value="100">
															100 Level
														</option>
														<option value="200">
															200 Level
														</option>
														<option value="300">
															300 Level
														</option>
														<option value="400">
															400 Level
														</option>
														<option value="500">
															500 Level
														</option>
													</Input>
												</FormGroup>
											</Col>
											<Col lg="6">
												<FormGroup>
													<label
														className="form-control-label"
														htmlFor="input-username"
													>
														Matric Number
													</label>
													<Input
														className="form-control-alternative"
														defaultValue={
															props.user.matric
														}
														id="input-username"
														placeholder="Username"
														type="text"
														disabled
													/>
												</FormGroup>
											</Col>
										</Row>
									</div>
									<hr className="my-4" />
									{/* Address */}
									<h6 className="heading-small text-muted mb-4">
										Contact information
									</h6>
									<div className="pl-lg-4">
										<Row>
											<Col md="12">
												<FormGroup>
													<label
														className="form-control-label"
														htmlFor="input-address"
													>
														Hostel Address
													</label>
													<Input
														className="form-control-alternative"
														defaultValue={
															props.user
																.hostel_address
														}
														id="input-address"
														placeholder="Hostel Address"
														type="text"
														name="hostel_address"
														onChange={(e) =>
															changeHandler(e)
														}
													/>
												</FormGroup>
											</Col>
										</Row>
										<Row>
											<Col lg="6">
												<FormGroup>
													<label
														className="form-control-label"
														htmlFor="input-phone"
													>
														Phone Number
													</label>
													<Input
														className="form-control-alternative"
														defaultValue={
															props.user.phone
														}
														id="input-phone"
														placeholder="Phone"
														name="phone"
														type="text"
														onChange={(e) =>
															changeHandler(e)
														}
													/>
												</FormGroup>
											</Col>
											<Col lg="6">
												<FormGroup>
													<label
														className="form-control-label"
														htmlFor="input-email"
													>
														Email
													</label>
													<Input
														className="form-control-alternative"
														defaultValue={
															props.user.email
														}
														id="input-email"
														placeholder="Email"
														type="email"
														disabled
													/>
												</FormGroup>
											</Col>
										</Row>
									</div>
								</Form>
							</CardBody>
							<CardFooter>
								{(value.phone !== props.user.phone ||
									value.hostel_address !==
										props.user.hostel_address ||
									value.level !== props.user.level) && (
									<Col className="text-right">
										<Button
											color="success"
											href="#pablo"
											onClick={(e) => updateProfile()}
											size="sm"
										>
											Update Profile
										</Button>
									</Col>
								)}
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Profile;
