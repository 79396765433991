import React, { useState } from "react";
import { HeaderMeta } from "../../layouts";
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Link, withRouter } from "react-router-dom";
import AuthService from "../../services/AuthService";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { Player } from "@lottiefiles/react-lottie-player";
import { LoadingIndicator } from "../../assets/images";

import "./style.scss";

const SignUpPage = (props) => {
    const [value, setValue] = useState({
        first_name: "",
        last_name: "",
        matric: "",
        faculty: "",
        department: "",
        level: "",
        hostel_address: "",
        phone: "",
        email: "",
        password: "",
        confirm_password: "",
        showPassword: false,
        showCPassword: false,
    });

    const validator = new SimpleReactValidator({
        className: "errorMessage text-danger",
    });

    const changeHandler = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
        validator.showMessages();
    };

    const handleClickShowPassword = () => {
        setValue({ ...value, showPassword: !value.showPassword });
    };

    const handleClickShowPassword2 = () => {
        setValue({ ...value, showCPassword: !value.showCPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const submitForm = (e) => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            setValue({ ...value, loader: true });
            AuthService.userRegistration(value)
                .then(function (response) {
                    setValue({
                        first_name: "",
                        last_name: "",
                        // matric: "",
                        // faculty: "",
                        // department: "",
                        level: "",
                        hostel_address: "",
                        phone: "",
                        email: "",
                        password: "",
                        confirm_password: "",
                    });
                    toast.success(response.data.message);
                    window.location.href = "/login";
                })
                .catch((err) => {
                    toast.error(err.response.data.message);
                });
        } else {
            validator.showMessages();
            toast.error("Empty field is not allowed!");
        }
    };

    return (
        <>
            <HeaderMeta title={"Sign Up"} />

            <Grid className="loginWrapper">
                <Grid className="loginForm">
                    <h4 className="text-center">
                        MSSN-LASU EPE Membership Registration
                    </h4>
                    <p>Fill and submit the form below</p>
                    <form onSubmit={submitForm}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="First Name"
                                    value={value.first_name}
                                    variant="outlined"
                                    name="first_name"
                                    label="First Name"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={changeHandler}
                                    onChange={changeHandler}
                                />
                                {validator.message(
                                    "first_name",
                                    value.first_name,
                                    "required"
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Last Name"
                                    value={value.last_name}
                                    variant="outlined"
                                    name="last_name"
                                    label="Last Name"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={changeHandler}
                                    onChange={changeHandler}
                                />
                                {validator.message(
                                    "last_name",
                                    value.last_name,
                                    "required"
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Email"
                                    value={value.email}
                                    variant="outlined"
                                    name="email"
                                    label="E-mail"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={changeHandler}
                                    onChange={changeHandler}
                                />
                                {validator.message(
                                    "email",
                                    value.email,
                                    "required|email"
                                )}
                            </Grid>
                          
                            <Grid item xs={12} sm={6}>
                                <InputLabel id="level-select-label">
                                    Level
                                </InputLabel>
                                <Select
                                    labelId="level-select-label"
                                    id="level-select"
                                    value={value.level}
                                    name="level"
                                    label="Level"
                                    fullWidth
                                    onChange={changeHandler}
                                >
                                    <MenuItem value={"100"}>100 Level</MenuItem>
                                    <MenuItem value={"200"}>200 Level</MenuItem>
                                    <MenuItem value={"300"}>300 Level</MenuItem>
                                    <MenuItem value={"400"}>400 Level</MenuItem>
                                    <MenuItem value={"500"}>500 Level</MenuItem>
                                    <MenuItem value={"Diploma"}>
                                        Diploma
                                    </MenuItem>
                                </Select>
                                {validator.message(
                                    "level",
                                    value.level,
                                    "required"
                                )}
                            </Grid>
                          
                            <Grid item xs={12}>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Phone Number"
                                    value={value.phone}
                                    variant="outlined"
                                    name="phone"
                                    label="Phone Number"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={changeHandler}
                                    onChange={changeHandler}
                                />
                                {validator.message(
                                    "phone",
                                    value.phone,
                                    "required"
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    type={value.showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    value={value.password}
                                    variant="outlined"
                                    name="password"
                                    label="Password"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {value.showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onBlur={changeHandler}
                                    onChange={changeHandler}
                                />
                                {validator.message(
                                    "password",
                                    value.password,
                                    "required|min:8"
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    type={value.showCPassword ? "text" : "password"}
                                    placeholder="Confirm Password"
                                    value={value.confirm_password}
                                    variant="outlined"
                                    name="confirm_password"
                                    label="Confirm Password"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword2}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {value.showCPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onBlur={changeHandler}
                                    onChange={changeHandler}
                                />
                                {validator.message(
                                    "confirm_password",
                                    value.confirm_password,
                                    `in:${value.password}`,
                                    { messages: { in: "Password mismatch!" } }
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid className="formFooter">
                                    <Button
                                        fullWidth
                                        className="cBtn cBtnLarge cBtnTheme"
                                        type="submit"
                                    >
                                        {value.loader ? (
                                            <div className="d-flex">
                                                Finalizing Account....
                                                <Player
                                                    loop={true}
                                                    src={LoadingIndicator}
                                                    style={{
                                                        width: 18,
                                                        marginLeft: 5,
                                                    }}
                                                    autoplay={true}
                                                />
                                            </div>
                                        ) : (
                                            "Complete Registration"
                                        )}
                                    </Button>
                                </Grid>
                                <p className="noteHelp">
                                    Already a member?{" "}
                                    <Link to="/login">Sign In</Link>
                                </p>
                                <p className="text-center">
                                    Go back <Link to="/">Home</Link>
                                </p>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </>
    );
};

export default withRouter(SignUpPage);