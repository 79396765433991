import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { thumbnail } from "../../../assets/images";
import EventService from "../../../services/EventService";
import { toast } from "react-toastify";

const SidebarWrap = () => {
	let [events, setEvents] = React.useState([]);

	useEffect(() => {
		fetchAllEvents();
	}, []);

	const fetchAllEvents = () => {
		EventService.fetchEventList().then(function (response) {
			if (response.flag) {
				setEvents(response.data);
			} else {
				toast.error(
					response && response.data && response.data.message
						? response.data.message
						: "Something went wrong. Try again"
				);
			}
		});
	};

	function DisplayDate(date) {
		const dates = new Date(date);
		const day = dates.getDate();
		const month = dates.getMonth() + 1;
		const year = dates.getFullYear();
		const time = day + "/" + month + "/" + year;
		return time;
	}

	return (
		<div className="col col-lg-4 col-12">
			<div className="wpo-blog-sidebar">
				<div className="widget recent-post-widget">
					<h3>Upcoming Events</h3>
					<div className="posts">
						{events.length === 0 ? (
							<h3 className="py-5 text-center">
								{" "}
								No data to show{" "}
							</h3>
						) : (
							<>
								{events.map((item, i) => (
									<div className="post my-5">
										<div className="img-holder">
											<img
												src={
													item.image
														? item.image.formats
																.thumbnail.url
														: thumbnail
												}
												alt={item.name}
												className="rounded z-depth-1-half img-fluid img-thumbnail h-100"
											/>
										</div>
										<div className="details py-2">
											<h4>
												<Link to={"event/" + item.id}>
													{item.name}
												</Link>
											</h4>
											<span className="date">
												{DisplayDate(item.date)}
											</span>
										</div>
									</div>
								))}
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SidebarWrap;
