import React from "react";
import { Card } from "react-bootstrap";
import comment from "../../../../../assets/img/icons/common/comment.png"
import download from "../../../../../assets/img/icons/common/download.png"
import "./style.scss"

const Courses = ({filterCourses}) => {
  
  return (
    <section className="section">
    {filterCourses.map(
      ({ course, courseCode, year, color }, i) => (
        
        <div className="d-flex" key={i}>
          <Card.Body 
          
          >
            <div
            className="cardbackground">

            <Card.Header
              style={{ background: color, borderRadius: "30px 0 30px 0px" }}>
                
                <h2> {courseCode}</h2>
              <p> 
                {course}
              </p>
            </Card.Header>
            <div 
            
            className="p-2 d-flex justify-content-between align-items-center w-100">
            <Card.Text>
                {year}
            </Card.Text>
            <div className="w-25 d-flex justify-content-between ">
            <Card.Text>
                <img src={comment} alt="comment"  />
                30
            </Card.Text>
            <Card.Text>
                <img src={download} alt="download" />
            </Card.Text>
            </div>
            </div>
                </div>
          </Card.Body>
        </div>
      )
    )}
        </section>
  );
};

export default Courses;
