import React from "react";
import { Link, useLocation } from "react-router-dom";
import HeaderTopbar from "./HeaderTopbar";
import MobileMenu from "./MobileMenu";
import { Logo } from "../../../assets/images";

import "./style.css";
import Dropdown from "react-bootstrap/Dropdown";
import SplitButton from "react-bootstrap/SplitButton";
import { Button } from "react-bootstrap";
import { ButtonGroup } from "react-bootstrap";

const menus = [
	{
		id: 1,
		title: "Home",
		link: "/home",
	},

	{
		id: 2,
		title: "About",
		link: "/about",
	},

	{
		id: 3,
		title: "Programs",
		link: "/programs",
		submenus: [
			{
				id: 5,
				title: "Ramadan",
				link: "/ramadan",
			},
			{
				id: 6,
				title: "Dawah Camp",
				link: "/dawahcamp",
			},
		],
	},
	{
		id: 4,
		title: "Events",
		link: "/events",
	},

	{
		id: 7,
		title: "Projects",
		link: "/projects",
		submenus: [
			{
				id: 5,
				title: "Academics",
				link: "/academics",
			},
		],
	},
	{
		id: 8,
		title: "Contact",
		link: "/contact",
	},
	{
		id: 11,
		title: "Magazine",
		link: "/magazine/download",
	},
];

const Header = () => {
	const currentPathname = useLocation().pathname;

	const ClickHandler = () => {
		window.scrollTo(10, 0);
	};

	return (
		<div className="middle-header">
			<HeaderTopbar />

			<div className="header-style-3">
				<div className="container">
					<div className="header-content row justify-content-between justify-content-lg-center py-3 py-lg-0">
						<div className="col-lg-3 col-md-4 col-sm-4 col-4 align-self-center">
							<Link
								onClick={ClickHandler}
								to="/home"
								title=""
								className="overflow-hidden"
								style={{ width: "fit-content" }}
							>
								<img
									src={Logo}
									alt=""
									style={{
										width: "58px",
										height: "58px",
										transform: "scaleX(3)",
									}}
								/>
							</Link>
						</div>
						<div className="col-lg-8 d-lg-block d-none">
							<nav className="desktop-nav">
								<ul
									className="d-flex align-items-center"
									style={{ gap: "40px" }}
								>
									{menus.map((item) => {
										if (!item.submenus) {
											return (
												<li key={item.id}>
													<Link
														onClick={ClickHandler}
														to={item.link}
														className={
															currentPathname ===
															item.link
																? "active"
																: ""
														}
													>
														{item.title}
													</Link>
												</li>
											);
										}

										return (
											<li key={item.id}>
												<Dropdown
													as={ButtonGroup}
													className="d-flex align-items-center gap-2"
												>
													<Link
														onClick={ClickHandler}
														to={item.link}
														className={
															currentPathname ===
															item.link
																? "active"
																: ""
														}
													>
														{item.title}
													</Link>

													<Dropdown.Toggle
														split
														variant="success"
														id="dropdown-split-basic"
														className="bg-none"
														style={{
															backgroundColor:
																"transparent",
															"border-color":
																"transparent",
															boxShadow: "none",
															paddingRight: "0",
															color: "black",
														}}
													/>

													<Dropdown.Menu>
														{item.submenus.map(
															(item) => {
																return (
																	<Dropdown.Item
																		key={
																			item.id
																		}
																		href={
																			item.link
																		}
																		onClick={
																			ClickHandler
																		}
																		className={
																			currentPathname ===
																			item.link
																				? "active"
																				: ""
																		}
																	>
																		{
																			item.title
																		}
																	</Dropdown.Item>
																);
															}
														)}
													</Dropdown.Menu>
												</Dropdown>
											</li>
										);
									})}
								</ul>
							</nav>
						</div>
						<div className=" col-2 d-lg-none position-relative">
							<MobileMenu />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
