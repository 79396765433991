import React from "react";
import { toast } from "react-toastify";
import AuthService from "../../services/AuthService";
import { Player } from "@lottiefiles/react-lottie-player";
import { LoadingIndicator, NotFound, Success } from "../../assets/images";
import { HeaderMeta } from "../../layouts";

export default class AccountVerification extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoader: false,
			failure: false,
		};
	}

	componentDidMount() {
		let _this = this;
		let query = new URLSearchParams(_this.props.location.search);
		if (query.get("confirmation")) {
			_this.setState({ isLoader: true }, () => {
				AuthService.userVerification(query.get("confirmation")).then(
					function (response) {
						if (response.flag) {
							_this.setState({ isLoader: false }, () => {
								setTimeout(function () {
									window.location.replace("/login");
								}, 5000);
							});
						} else {
							_this.setState(
								{ failure: true, isLoader: false },
								() => {
									toast.error(
										response.message
											? response.message
											: "Something went wrong. Try again"
									);
								}
							);
						}
					}
				);
			});
		} else {
			_this.setState({ failure: true }, () => {
				toast.error("Invalid or expired token");
			});
		}
	}

	render() {
		const { isLoader, failure } = this.state;
		return (
			<>
				<HeaderMeta title={"Account Verification"} />
				{!failure ? (
					<>
						{isLoader ? (
							<div className="row text-center py-5">
								<div className="col-md-6 col-11 px-5 mx-auto">
									<img
										src={LoadingIndicator}
										width={320}
										alt="loading"
									/>
									<h3
										className="text-theme-primary font-weight-bold"
										style={{ marginTop: -30 }}
									>
										Activating Account
									</h3>
								</div>
							</div>
						) : (
							<div className="row text-center py-5">
								<div className="col-md-6 col-11 px-5 bg-success-light mx-auto">
									<Player
										src={Success}
										style={{
											width: 250,
											height: 250,
											margin: "0 auto",
										}}
										loop
										autoplay
									/>
									<div className="px-md-5 mb-5">
										<h3 className="font-weight-bold my-3">
											Voila! Your Account has been
											successfully activated.
										</h3>
										<h6 className="mb-4">
											Please wait, you will be redirected
											in 5 seconds...
										</h6>
									</div>
								</div>
							</div>
						)}
					</>
				) : (
					<div className="row text-md-left text-center py-5 mb-5">
						<div className="col-md-8 col-11 p-md-5 py-5 bg-primary-light mx-auto">
							<div className="d-md-flex justify-md-content-between">
								<Player
									src={NotFound}
									style={{ width: 300, margin: "0 auto" }}
									loop
									autoplay
								/>
								<div className="my-auto">
									<h2
										className={
											"font-weight-bold mb-3 text-danger"
										}
									>
										Sorry, we can't activate your account
									</h2>
								</div>
							</div>
						</div>
					</div>
				)}
			</>
		);
	}
}
