import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import ProjectService from "../../../../services/ProjectService";
import { toast } from "react-toastify";
import NoContent from "../../../general/NoContent";
import MarkdownView from "react-showdown";
import "./style.css";

const ProjectSingle = (props) => {
	let [project, setProject] = React.useState({});
	let [isLoading, setIsLoading] = React.useState(true);

	useEffect(() => {
		fetchSingleProject(props.ID);
	}, [props.ID]);

	const fetchSingleProject = (ID) => {
		ProjectService.fetchSingleProject(ID).then(function (response) {
			if (response.flag) {
				setIsLoading(false);
				setProject(response.data);
			} else {
				toast.error(
					response && response.data && response.data.message
						? response.data.message
						: "Something went wrong. Try again"
				);
			}
		});
	};

	return (
		<section className="service-single-section section-padding">
			<div className="container">
				<div className="row">
					<div className="col col-lg-12 col-12">
						<div className="service-single-content">
							<h2>{project.name}</h2>
							{isLoading && (
								<div className="row my-4">
									<NoContent number={5} />
								</div>
							)}

							{!isLoading && project && (
								<>
									<Card.Body>
										<Card.Text>
											<MarkdownView
												markdown={project.description}
												options={{
													tables: true,
													emoji: true,
												}}
											/>
										</Card.Text>
									</Card.Body>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ProjectSingle;
