import React from "react";
import { NormalLayout, HeaderMeta } from "../../layouts";
import PageTitle from "../../components/general/pagetitle";
import { Projects } from "../../components/sections/ProjectPage";

const ProjectPage = () => {
	return (
		<NormalLayout>
			<HeaderMeta title={"Projects"} />

			<PageTitle pageTitle={"Projects"} pagesub={"Projects"} />
			<Projects serviceClass={"s2"} />
		</NormalLayout>
	);
};
export default ProjectPage;
