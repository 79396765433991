import React from "react";
import "./style.css";
import { useFileDownload } from "../../../hooks/useFileDownload";

const Proposal = (props) => {
	const { downloadFile } = useFileDownload("/The Guide Magazine FV.pdf", {
		downloadOnLoad: false,
	});

	return (
		<section
			id="call-to-action"
			class="gradient-to-right py-3 my-3 text-light call-to-action"
			aria-label="cta"
		>
			<div class="container py-3 my-3">
				<div class="row align-items-center">
					<div class="col-lg-8 col-md-7">
						<h3 class="size-2 no-margin" style={{ color: "white" }}>
							Want to Know more?
						</h3>
					</div>

					<div class="col-lg-4 col-md-5 text-right">
						<button
							onClick={downloadFile}
							class="btn-custom rounded-lg py-3 text-white scroll-to"
						>
							Download Proposal
						</button>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Proposal;
