import React from "react";
import Navbar from "./Navbar";
import Footer from "./footer";
import Scrollbar from "./scrollbar";

const NormalLayout = ({ children, footerClass }) => {
	return (
		<>
			<Navbar />
			{children}
			<Footer footerClass={footerClass || "wpo-ne-footer-2"} />
			<Scrollbar />
		</>
	);
};
export default NormalLayout;
