import React, { useEffect } from "react";
import PaymentService from "../../services/PaymentService";
import { toast } from "react-toastify";
import TableLoader from "../../layouts/DashboardLayout/Footers/TableLoader";
import Header from "../../layouts/DashboardLayout/Headers/Header.js";
// reactstrap components
import {
	Card,
	CardHeader,
	CardFooter,
	Table,
	Container,
	Row,
} from "reactstrap";
// core components

const Tables = (props) => {
	let [payments, setPayments] = React.useState([]);
	let [isLoading, setIsLoading] = React.useState(true);

	useEffect(() => {
		fetchPaymentHistoryList(props.user.id);
	}, [props.user.id]);

	const fetchPaymentHistoryList = (data) => {
		PaymentService.fetchPaymentHistoryList(data).then(function (response) {
			if (response.flag) {
				setIsLoading(false);
				setPayments(response.data);
			} else {
				toast.error(
					response && response.message
						? response.message
						: "Something went wrong. Try again"
				);
			}
		});
	};

	return (
		<>
			<Header user={props.user} />
			{/* Page content */}
			<Container className="mt--7" fluid>
				{/* Table */}
				<Row>
					<div className="col">
						<Card className="shadow">
							<CardHeader className="border-0">
								<h3 className="mb-0">
									Annual Due Payment History
								</h3>
							</CardHeader>
							<Table
								className="align-items-center table-flush"
								responsive
							>
								<thead className="thead-light">
									<tr>
										<th scope="col">#</th>
										<th scope="col">Transaction ID</th>
										<th scope="col">Description</th>
										<th scope="col">Amount</th>
										<th scope="col">Date</th>
									</tr>
								</thead>
								<tbody>
									{isLoading && <TableLoader />}

									{!isLoading && (
										<>
											{payments.length === 0 ? (
												<h3 className="py-3 text-center">
													{" "}
													No payment Found
												</h3>
											) : (
												<>
													{payments.map((item, i) => (
														<tr>
															<th scope="row">
																{i + 1}
															</th>
															<td>
																{
																	item.transaction_id
																}
															</td>
															<td>
																{
																	item.payment_type
																}
															</td>
															<td>
																{
																	item.plan_id
																		.amount
																}
															</td>
															<td>
																{new Date(
																	item.created_at
																).toDateString()}
															</td>
														</tr>
													))}
												</>
											)}
										</>
									)}
								</tbody>
							</Table>
							<CardFooter className="py-4"></CardFooter>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

export default Tables;
