import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import "./style.css";

const Header = (props) => {
	let [prayer, setPrayer] = useState(null);
	const mountedRef = useRef(true);

	useEffect(() => {
		prayerTimes();

		return () => {
			mountedRef.current = false;
		};
	}, []);

	const prayerTimes = async () => {
		await axios({
			method: "GET",
			url: "https://api.aladhan.com/v1/timingsByAddress?address=Lagos",
		})
			.then((response) => {
				setPrayer(response.data.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<div
			className="header bg-gradient-infos pb-8 pt-5 pt-md-8"
			ref={mountedRef}
		>
			<Container fluid>
				<div className="header-body">
					{/* Card stats */}
					<Row>
						<Col lg="6" xl="6">
							<Card className="card-stats mb-4 mb-xl-0">
								<CardBody>
									<Row>
										<div className="col">
											<CardTitle
												tag="h5"
												className="text-uppercase text-muted mb-0"
											>
												MSSN LASU EPE
											</CardTitle>
											<span className="h2 font-weight-bold mb-0">
												Assalamualaikum,{" "}
												{props.user.first_name}{" "}
												{props.user.last_name}
											</span>
										</div>
										<Col className="col-auto">
											<div className="icon icon-shape bg-success text-white rounded-circle shadow">
												<i className="fas fa-star-and-crescent"></i>
											</div>
										</Col>
									</Row>
									<p className="mt-3 mb-0 text-muted text-sm">
										{prayer && (
											<span className="text-nowrap">
												{prayer.date.hijri.month.en}{" "}
												{prayer.date.hijri.day},{" "}
												{prayer.date.hijri.year}
											</span>
										)}
									</p>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			</Container>
		</div>
	);
};

export default Header;
