import React from "react";
import { NormalLayout, HeaderMeta } from "../../layouts";
// import PageTitle from "../../components/general/pagetitle";

const Magazine = () => {
	return (
		<NormalLayout>
			<HeaderMeta title={"MAGAZINE INTEREST FORM"} />
			{/* <PageTitle
				pageTitle={"MAGAZINE INTEREST FORM"}
				// pagesub={"Membership Registration Form"}
			/> */}
			<div className="d-flex justify-content-center">
				<iframe
					title="Magazine Form"
					src="https://docs.google.com/forms/d/e/1FAIpQLSfqMLbUQZ78njHOXgyN41cH3WniZ0HqPnv5LSqQkWSBuke47Q/viewform?embedded=true"
					width="700"
					height="1400"
					frameborder="0"
					marginheight="0"
					marginwidth="0"
				>
					Loading…
				</iframe>
			</div>
		</NormalLayout>
	);
};

export default Magazine;
