import React, { useState } from "react";
import { Collapse, CardBody, Card } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import "./style.css";

const menus = [
	{
		id: 1,
		title: "Home",
		link: "/home",
	},

	{
		id: 2,
		title: "About",
		link: "/about",
	},

	{
		id: 3,
		title: "Programs",
		link: "/programs",
	},
	{
		id: 4,
		title: "Events",
		link: "/events",
	},
	{
		id: 5,
		title: "Academics",
		link: "/academics",
	},
	{
		id: 7,
		title: "Projects",
		link: "/projects",
	},
	{
		id: 8,
		title: "Contact",
		link: "/contact",
	},
	{
		id: 9,
		title: "IPW",
		link: "/ipw",
	},
	{
		id: 10,
		title: "HCDP",
		link: "/hcdp",
	},
	{
		id: 11,
		title: "Download Magazine",
		link: "/magazine/download",
	},
	{
		id: 5,
		title: "Ramadan",
		link: "/ramadan",
	},
];

export default function MobileMenu() {
	const [isMenuShow, setIsMenuShow] = useState(false);
	const [isOpen, setIsOpen] = useState(0);

	const currentPathname = useLocation().pathname;

	const menuHandler = () => {
		setIsMenuShow(!isMenuShow);
	};

	const updateOpened = (id) => {
		setIsOpen(id === this.state.isOpen ? 0 : id);
	};

	const ClickHandler = () => {
		window.scrollTo(10, 0);
		setIsMenuShow(false);
	};

	return (
		<>
			<div className={`mobileMenu ${isMenuShow ? "show" : ""}`}>
				<ul className="responsivemenu">
					{menus.map((item) => {
						return (
							<li key={item.id}>
								{!item.submenu ? (
									<Link
										onClick={ClickHandler}
										to={item.link}
										className={
											currentPathname === item.link
												? "active"
												: ""
										}
									>
										{item.title}
									</Link>
								) : (
									<>
										<p onClick={updateOpened(item.id)}>
											{item.title}

											<i
												className="fa fa-angle-right"
												aria-hidden="true"
											></i>
										</p>

										<Collapse isOpen={item.id === isOpen}>
											<Card>
												<CardBody>
													<ul>
														{item.submenu.map(
															(submenu) => (
																<li
																	key={
																		submenu.id
																	}
																>
																	<Link
																		onClick={
																			ClickHandler
																		}
																		className="active"
																		to={
																			submenu.link
																		}
																	>
																		{
																			submenu.title
																		}
																	</Link>
																</li>
															)
														)}
													</ul>
												</CardBody>
											</Card>
										</Collapse>
									</>
								)}
							</li>
						);
					})}
				</ul>
			</div>

			<div
				className={`dimmer ${isMenuShow ? "show" : ""}`}
				onClick={ClickHandler}
			></div>

			<div className="showmenu" onClick={menuHandler}>
				<i id="i-right" className="fa fa-bars" aria-hidden="true"></i>
			</div>
		</>
	);
}
