import React from "react";
import "./style.scss"
const SearchBar = ({handleChange}) => {

  return (
    <div className="searchContainer">
      <input className="searchBar"
        type="search"
        placeholder="Search Past Question"
        onChange={handleChange}
      />
    </div>
  );
};

export default SearchBar;
