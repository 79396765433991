import axios from "axios";
import env from "../env.json";

const ProjectService = {
	//Fetch Project Items
	async fetchProjectList() {
		return await axios
			.get(env.API_URL + "projects")
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;
				return response;
			})
			.catch(function (error) {
				return error;
			});
	},

	//Fetch Single Project Item
	async fetchSingleProject(id) {
		return await axios
			.get(env.API_URL + "projects/" + id)
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;
				return response;
			})
			.catch(function (error) {
				return error;
			});
	},
};

export default ProjectService;
