import React, { useEffect, useState } from "react";
import "./ticketDetails.css";
import axios from "axios";
import { rangeRight } from "lodash";
import { PaystackConsumer } from 'react-paystack';
import env from "../../../src/env.json";
import { toast } from "react-toastify";
import CircularProgress from '@material-ui/core/CircularProgress';

const ramadanTickets = [
	{
		name: "Iftar Ticket",
		price: 300,
		slug: "Iftar Ticket"
	},
	{
		name: "Sahur Ticket",
		price: 300,
		slug: "Sahur Ticket"

	},
]

const MssnSuccessPayment = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const ticketDetails = JSON.parse(localStorage.getItem("ticketDetails"));
  const userAuthDetails = JSON.parse(localStorage.getItem("userAuthDetails"));
  const token = JSON.parse(localStorage.getItem("token"));
  const isAuthenticated = userAuthDetails ? true : false;
  // const ticketUrl = `https://main-website-backend-lm0y.onrender.com/buy-tickets/details`;
  const ticketUrl = `http://localhost:3000/buy-tickets/details`;
  
  const [fetchDetail, setFetchDetail] = useState({});
  const configure = {
    headers: {
    "Authorization" : `Bearer ${token}`
  }}
  async function fetchData() {
    try {
      const fetchDetails = await axios.get(ticketUrl, configure);
      setFetchDetail(fetchDetails);
      
    } catch (error) {
      toast.error(error?.response?.data?.message)
      window.location.href = "/404"
    }
  }
  useEffect(() => {
    fetchData();
  }, [ticketUrl, configure]);

  const ticketDetailsfrmAPI = fetchDetail?.data;
  const status = ticketDetailsfrmAPI?.isTicketUsed ? "USED" : "NOT USED";
  const isPaid = ticketDetailsfrmAPI?.paymentStatus !== "Not Paid" ? true : false ;
  const dateTimeString = ticketDetailsfrmAPI?.createdAt;
  const usedAt = ticketDetailsfrmAPI?.usedAt;
  
  const config = {
		reference: new Date().getTime().toString(),
		email: userAuthDetails?.email,
		amount: parseInt(ramadanTickets[0].price) * 100,
		// publicKey: 'pk_live_a3e7acbf6ed58c6a39da7a5972f15b99029f9ade',
		publicKey: 'pk_test_d9b5eb346e5c76533beb00a05822c730c74bdfcd',
	};

  const handleSuccess = async (data) => {
		try {
			setIsLoading(true);
			toast.success("Check your mail for payment receipt.");
			
			const ticketDetailsResponse = await axios.post(env.API_URL + "buy-tickets/updatewithpaymentRef", {
				ticketID: ticketDetailsfrmAPI?._id,
				paymentRef: data.reference,
			});

			const ticketDetails = ticketDetailsResponse.data;
			localStorage.setItem("ticketDetails", JSON.stringify(ticketDetails));

			window.location.reload(); 
      await fetchData()
		} catch (err) {
			toast.error(err.response?.data?.message);
		} finally {
			setIsLoading(false);
    }
	};
	
	const handleClose = () => {
		toast.error('Payment closed!');
	};
	
	const componentProps = {
		...config,
		text: 'Proceed to Payment',
		onSuccess: (data) => handleSuccess(data),
		onClose: handleClose,
	};

  
  const date = new Date(dateTimeString);

  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
  const paidAT = formattedDate + ", " + formattedTime;

  useEffect(() => {
    setIsMounted(true);
  });
  return isAuthenticated ? (
    <div className="success-payment-container">
      <h1>TICKET DETAILS</h1>
      <div className="payment-details">
        <h1 className="payment-details-title">Payment details</h1>
        <div className="payment-details-items">
          <div className="payment-details-item">
            <p>EMAIL: </p>
            <strong className="success-strong">
              {ticketDetailsfrmAPI?.email}
            </strong>
          </div>
          <div className="payment-details-item">
            <p>AMOUNT:</p>
            <strong className="success-strong">
              {ticketDetailsfrmAPI?.totalPrice}
            </strong>
          </div>
          <div className="payment-details-item">
            <p>TICKET STATUS:</p>
            <strong className="success-strong">{status}</strong>
          </div>
          {ticketDetailsfrmAPI?.isTicketUsed && (
            <div className="payment-details-item">
              <p>USED AT:</p>
              <strong className="success-strong">{usedAt}</strong>
            </div>
          )}
          <hr />
          <div className="payment-details-item">
            <p>TICKET ID:</p>
            <strong className="success-strong">
              {ticketDetailsfrmAPI?.customticketID ||
                ticketDetailsfrmAPI?.ticketNo}
            </strong>
          </div>
          {isPaid && (
            <div>
              <div className="payment-details-item">
                <p>PAID AT:</p>
                <strong className="success-strong">{paidAT}</strong>
              </div>
              <div className="payment-details-item">
                <p>PAID TO:</p>
                <strong className="success-strong">MSSN LASU EPE</strong>
              </div>
            </div>
          )}
          {!isPaid && (
            <div>
              <PaystackConsumer {...componentProps}>
                {({ initializePayment }) => (
                <button
                  onClick={async () => {
                    initializePayment(handleSuccess, handleClose)
                  }
                  }
                  class="mt-2 float-right btn btn-outline-success"
                >
                  PROCEED TO PAY
                </button>
              )}
              </PaystackConsumer>
            </div>
          )}
          <br />
          <h3 className="success-strong">
            Pls endeavour to screenshot this ticket.
          </h3>
        </div>
      </div>
      <h3 className="success-strong">
        DO NOT TRY TO BUY THIS TICKET AGAIN. THIS COULD LEAD TO LOSS OF MONEY.
      </h3>
      <button className="back-to-home-button">
        <a href="/home">Back To Home</a>
      </button>
    </div>
  ) : (
    (window.location.href = "/login")
  );
};

export default MssnSuccessPayment;
