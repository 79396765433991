import React, { useEffect, useState } from "react";
import PaymentService from "../../services/PaymentService";
import ProgramService from "../../services/ProgramService";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { Player } from "@lottiefiles/react-lottie-player";
import { LoadingIndicator } from "../../assets/images";
import MarkdownView from "react-showdown";
import {
	AnnouncementLoader,
	DueLoader,
} from "../../layouts/DashboardLayout/Loaders";
import Header from "../../layouts/DashboardLayout/Headers/Header";
// reactstrap components
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	Container,
	Row,
	Col,
	Popover,
	PopoverHeader,
	PopoverBody,
} from "reactstrap";
import { EventSection } from "../../components/sections/Dashboard";
import "./style.css";

function MyVerticallyCenteredModal(props) {
	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Announcements
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					<MarkdownView
						markdown={props.description}
						options={{ tables: true, emoji: true }}
					/>
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={props.onHide}>Close</Button>
			</Modal.Footer>
		</Modal>
	);
}

const Dashboard = (props) => {
	let [dues, setDues] = React.useState([]);
	let [announcement, setAnnouncement] = React.useState([]);
	let [announcements, setAnnouncements] = React.useState([]);
	const [modalShow, setModalShow] = React.useState(false);
	const [loader, setLoader] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(true);
	const [isLoading1, setIsLoading1] = React.useState(true);
	const [popoverOpen, setPopoverOpen] = useState(false);

	const toggle = () => setPopoverOpen(!popoverOpen);

	useEffect(() => {
		fetchAllDues();
		fetchAnnouncement();
	}, []);

	const fetchAllDues = () => {
		PaymentService.fetchDues().then(function (response) {
			if (response.flag) {
				setDues(response.data);
				setIsLoading1(false);
			} else {
				toast.error(
					response && response.data && response.data.message
						? response.data.message
						: "Something went wrong. Try again"
				);
			}
		});
	};

	const fetchAnnouncement = () => {
		ProgramService.fetchAnnouncement().then(function (response) {
			if (response.flag) {
				if (response.data.length !== 0) {
					setAnnouncement(response.data[0].description);
					setAnnouncements(response.data[0]);
				}
				setIsLoading(false);
			} else {
				toast.error(
					response && response.data && response.data.message
						? response.data.message
						: "Something went wrong. Try again"
				);
			}
		});
	};

	const duePayment = () => {
		setLoader(true);

		PaymentService.duePayment().then((response) => {
			if (response.flag) {
				window.location.href = response.data.data.link;
			} else {
				setLoader(false);

				toast.error(
					// response && response.data && response.data.message
					response?.data?.message
						? response.data.message
						: "Something went wrong. Try again"
				);
			}
		});
	};

	return (
		<>
			<Header user={props.user} />
			{/* Page content */}
			<Container className="mt--7" fluid>
				<Row>
					<Col className="mb-5 mb-xl-0" xl="4">
						<Card className="shadow">
							<CardHeader className="bg-transparent">
								<Row className="align-items-center">
									<div className="col">
										<h5 className="text-uppercase ls-1 mb-1">
											Overview
										</h5>
									</div>
									<div className="col">
										<p id="Popover1" type="button">
											What is Annual Due?
										</p>
										<Popover
											placement="bottom"
											isOpen={popoverOpen}
											target="Popover1"
											toggle={toggle}
										>
											<PopoverHeader>
												Annual Due
											</PopoverHeader>
											<PopoverBody>
												Annual Due is a fee (#1000) that
												student members of MSSN LASU EPE
												Pay annually. The due is used
												for organizing MSSN Programs and
												activities.
											</PopoverBody>
										</Popover>
									</div>
								</Row>
							</CardHeader>
							<CardBody>
								{isLoading1 && <DueLoader />}
								{!isLoading1 && (
									<>
										{dues.find(
											(user) =>
												user.user_id.id ===
												props.user.id
										) ? (
											<>
												<p>
													You have Paid your Annual
													Due for this session
												</p>
												<p>
													Jazakumullah Khairan. May
													Allah reward you abundantly
												</p>
											</>
										) : (
											<>
												<p>
													You have not Paid your
													Annual Due for this session.
												</p>
												<div className="mt-2">
													<Button
														color="success"
														onClick={duePayment}
														size="lg"
													>
														{loader ? (
															<div className="d-flex">
																Processing....
																<span className="d-inline">
																	<Player
																		loop="true"
																		src={
																			LoadingIndicator
																		}
																		style={{
																			width: 18,
																			marginLeft: 5,
																		}}
																		autoplay="true"
																	/>
																</span>
															</div>
														) : (
															"Pay Now"
														)}
													</Button>
												</div>
											</>
										)}
									</>
								)}
							</CardBody>
						</Card>
					</Col>
					<Col xl="8">
						<Card className="shadow">
							<CardHeader className="bg-transparent">
								<Row className="align-items-center">
									<div className="col">
										<h5 className="text-uppercase ls-1 mb-1">
											Announcement
										</h5>
									</div>
								</Row>
							</CardHeader>
							<CardBody>
								{isLoading && <AnnouncementLoader />}
								{!isLoading && announcement.length !== 0 && (
									<>
										<div className="col">
											<ul
												className="sortable-list taskList list-unstyled ui-sortable"
												id="upcoming"
											>
												<li
													className="task-warning ui-sortable-handle"
													id="task1"
												>
													{announcement.length > 180
														? announcement.substr(
																0,
																180
														  ) + " ..."
														: announcement}
													<div className="clearfix"></div>
													<div className="mt-1">
														<p className="float-right">
															<button
																type="button"
																className="btn btn-success btn-sm waves-effect waves-light"
																onClick={() =>
																	setModalShow(
																		true
																	)
																}
															>
																View
															</button>
														</p>
														<MyVerticallyCenteredModal
															show={modalShow}
															onHide={() =>
																setModalShow(
																	false
																)
															}
															description={
																announcement
															}
														/>
														<p className="mb-0">
															<span className="font-bold font-secondary">
																{
																	announcements.announcer_title
																}
																,{" "}
																{
																	announcements.announcer
																}
															</span>
														</p>
													</div>
												</li>
											</ul>
										</div>
									</>
								)}
								{!isLoading & (announcement.length === 0) ? (
									<h3 className="py-5 text-center">
										{" "}
										No Announcement for now. Watch this
										space{" "}
									</h3>
								) : (
									""
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row className="mt-5">
					<Col className="mb-5 mb-xl-0" xl="12">
						<Card className="shadow">
							<CardHeader className="border-0">
								<Row className="align-items-center">
									<div className="col">
										<h3 className="mb-0">
											Programs/Events
										</h3>
									</div>
									<div className="col text-right">
										<Button
											color="success"
											href="/events"
											size="sm"
										>
											See all
										</Button>
									</div>
								</Row>
							</CardHeader>
							<CardBody>
								<EventSection limit={2} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Dashboard;
