import React from "react";
import { NormalLayout, HeaderMeta } from "../../layouts";
import PageTitle from "../../components/general/pagetitle";
import {
	Ramadan,
	Support,
	Payment,
} from "../../components/sections/RamadanPage";
import { ramadanimg } from "../../assets/images";

const AboutPage = () => {
	return (
		<NormalLayout>
			<HeaderMeta title={"Ramadan"} />

			<PageTitle pageTitle={"Ramadan"} pagesub={"Ramadan"} />
			<Ramadan aboutImg={ramadanimg} />
			<Support />
			<Payment />
		</NormalLayout>
	);
};
export default AboutPage;
