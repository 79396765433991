import React, { useEffect } from "react";
import JsFileDownloader from "js-file-downloader";

export function useFileDownload(fileUrl, { downloadOnLoad }) {
	useEffect(() => {
		if (downloadOnLoad) {
			downloadFile();
		}
	}, []);

	const downloadFile = () => {
		if (!fileUrl) {
			return;
		}

		new JsFileDownloader({
			url: fileUrl,
			// process: process,
		});

		// function process(event) {
		// 	if (!event.lengthComputable) return; // guard
		// 	var downloadingPercentage = Math.floor(
		// 		(event.loaded / event.total) * 100
		// 	);
		// 	// what to do ...
		// 	console.log(downloadingPercentage);
		// }
	};

	return { downloadFile };
}
