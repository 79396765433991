import React from "react";
import "./style.css";

const DawahCamp = (props) => {
	// const ClickHandler = () =>{
	//     window.scrollTo(10, 0);
	// }

	return (
		<div
			id="section-padding"
			className="wpo-about-area bg-ipw"
			style={{ backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
		>
			<div className="container">
				<div className="">
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-12">
							<div>
								<p className="ipw-text-head">
									Dawah Camp <br />
									2024
								</p>
							</div>
							<div className="pb-4">
								<h4
									className="text-white"
									style={{
										fontSize: "clamp(1rem, 5vw, 2.5rem)",
									}}
								>
									Theme: <br /> VICEGERENCY
								</h4>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 py-12">
							<div className="wpo-about-img-3">
								<img
									className="img-fluid rounded"
									src={props.aboutImg}
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DawahCamp;
