import React, { useState } from "react";
import EmailService from "../../../../services/EmailService";
import { toast } from "react-toastify";
import { Player } from "@lottiefiles/react-lottie-player";
import LoadingIndicator from "../../../../assets/images/lottie/loader.json";

import "./style.css";

const Newsletter = (props) => {
	const [email, setEmail] = useState("");
	const [loader, setLoader] = useState(false);

	const SubmitHandler = (e) => {
		e.preventDefault();
		setLoader(true);
		EmailService.subscribe({ email: email }).then(function (response) {
			setLoader(false);
			if (response.flag) {
				toast.success("Thanks for Subscribing!");
				setEmail("");
			} else {
				toast.error(
					response && response.data && response.data.message
						? response.data.message
						: "Something went wrong. Try again"
				);
			}
		});
	};

	return (
		<section className="wpo-news-letter-section">
			<div className="container">
				<div className="wpo-news-letter-wrap">
					<div className="row">
						<div className="col col-lg-10 offset-lg-1 col-md-8 offset-md-2">
							<div className="wpo-newsletter">
								<h3>Subscribe to Our Newsletter</h3>
								<p>
									Join our mailing list to get information
									about Islam and MSSN activities.
								</p>
								<div className="wpo-newsletter-form">
									<form onSubmit={SubmitHandler}>
										<div>
											<input
												type="text"
												placeholder="Enter Your Email"
												className="form-control"
												value={email}
												onChange={(e) =>
													setEmail(e.target.value)
												}
												required
											/>

											<button type="submit">
												{loader ? (
													<span className="d-inline-flex">
														Processing...{" "}
														<Player
															loop="true"
															src={
																LoadingIndicator
															}
															style={{
																width: 18,
																marginLeft: 5,
															}}
															autoplay="true"
														/>
													</span>
												) : (
													"Subscribe"
												)}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Newsletter;
