import React from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../services/AuthService";
// reactstrap components
import {
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Navbar,
	Nav,
	Container,
	Media,
} from "reactstrap";

const AdminNavbar = (props) => {
	const userLogout = () => {
		AuthService.userLogout().then(function () {
			window.location.replace("/login");
		});
	};

	const image = `https://ui-avatars.com/api/?background=FFF&color=5cb85c&bold=true&size=70&name=${props.user.first_name}+${props.user.last_name}`;

	return (
		<>
			<Navbar
				className="navbar-top navbar-dark"
				expand="md"
				id="navbar-main"
			>
				<Container fluid>
					{/* <Link
						className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
						to="/"
					>
						{props.brandText}
					</Link> */}
					<Nav className="align-items-center d-none d-md-flex" navbar>
						<UncontrolledDropdown nav>
							<DropdownToggle className="pr-0" nav>
								<Media className="align-items-center">
									<span className="avatar avatar-sm rounded-circle">
										<img alt="..." src={image} />
									</span>
									<Media className="ml-2 d-none d-lg-block">
										<span className="mb-0 text-sm font-weight-bold">
											{props.user.first_name +
												"  " +
												props.user.last_name}
										</span>
									</Media>
								</Media>
							</DropdownToggle>
							<DropdownMenu className="dropdown-menu-arrow" right>
								<DropdownItem
									className="noti-title"
									header
									tag="div"
								>
									<h6 className="text-overflow m-0">
										Welcome!
									</h6>
								</DropdownItem>
								<DropdownItem
									to="/dashboard/user-profile"
									tag={Link}
								>
									<i className="ni ni-single-02" />
									<span>My profile</span>
								</DropdownItem>
								<DropdownItem to="/programs" tag={Link}>
									<i className="ni ni-calendar-grid-58" />
									<span>Programs</span>
								</DropdownItem>
								<DropdownItem to="/donate" tag={Link}>
									<i className="ni ni-support-16" />
									<span>Donate</span>
								</DropdownItem>
								<DropdownItem divider />
								<DropdownItem
									href="#pablo"
									onClick={() => userLogout()}
								>
									<i className="ni ni-user-run" />
									<span>Logout</span>
								</DropdownItem>
							</DropdownMenu>
						</UncontrolledDropdown>
					</Nav>
				</Container>
			</Navbar>
		</>
	);
};

export default AdminNavbar;
