import React, { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import AuthService from "../../../services/AuthService";
// reactstrap components
import {
	Collapse,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Media,
	NavbarBrand,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Container,
	Row,
	Col,
} from "reactstrap";

const Sidebar = (props) => {
	const [collapseOpen, setCollapseOpen] = useState();
	const image = `https://ui-avatars.com/api/?background=5cb85c&color=FFF&bold=true&size=70&name=${props.user.first_name}+${props.user.last_name}`;

	// verifies if routeName is the one active (in browser input)
	// const activeRoute = (routeName) => {
	// 	return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
	// };
	// toggles collapse between opened and closed (true/false)
	const toggleCollapse = () => {
		setCollapseOpen((data) => !data);
	};
	// closes the collapse
	const closeCollapse = () => {
		setCollapseOpen(false);
	};
	// creates the links that appear in the left menu / Sidebar
	const createLinks = (routes) => {
		return routes.map((prop, key) => {
			return (
				<NavItem key={key}>
					<NavLink
						to={prop.layout + prop.path}
						tag={NavLinkRRD}
						onClick={closeCollapse}
						activeClassName="active"
					>
						<i className={prop.icon} />
						{prop.name}
					</NavLink>
				</NavItem>
			);
		});
	};

	const { routes, logo } = props;
	let navbarBrandProps;
	if (logo && logo.innerLink) {
		navbarBrandProps = {
			to: logo.innerLink,
			tag: Link,
		};
	} else if (logo && logo.outterLink) {
		navbarBrandProps = {
			href: logo.outterLink,
			target: "_blank",
		};
	}

	const userLogout = () => {
		AuthService.userLogout().then(function () {
			window.location.replace("/login");
		});
	};

	return (
		<Navbar
			className="navbar-vertical fixed-left navbar-light bg-white"
			expand="md"
			id="sidenav-main"
		>
			<Container fluid>
				{/* Toggler */}
				<button
					className="navbar-toggler"
					type="button"
					onClick={toggleCollapse}
				>
					<span className="navbar-toggler-icon" />
				</button>
				{/* Brand */}
				{logo ? (
					<NavbarBrand className="pt-0" {...navbarBrandProps}>
						<img
							alt={logo.imgAlt}
							className="navbar-brand-img"
							src={logo.imgSrc}
						/>
					</NavbarBrand>
				) : null}
				{/* User */}
				<Nav className="align-items-center d-md-none">
					<UncontrolledDropdown nav>
						<DropdownToggle nav>
							<Media className="align-items-center">
								<span className="avatar avatar-sm rounded-circle">
									<img alt="..." src={image} />
								</span>
							</Media>
						</DropdownToggle>
						<DropdownMenu className="dropdown-menu-arrow" right>
							<DropdownItem
								className="noti-title"
								header
								tag="div"
							>
								<h6 className="text-overflow m-0">Welcome!</h6>
							</DropdownItem>
							<DropdownItem
								to="/dashboard/user-profile"
								tag={Link}
							>
								<i className="ni ni-single-02" />
								<span>My profile</span>
							</DropdownItem>
							<DropdownItem to="/programs" tag={Link}>
								<i className="ni ni-calendar-grid-58" />
								<span>Programs</span>
							</DropdownItem>
							<DropdownItem to="/donate" tag={Link}>
								<i className="ni ni-support-16" />
								<span>Donate</span>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem onClick={() => userLogout()}>
								<i className="ni ni-user-run" />
								<span>Logout</span>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</Nav>
				{/* Collapse */}
				<Collapse navbar isOpen={collapseOpen}>
					{/* Collapse header */}
					<div className="navbar-collapse-header d-md-none">
						<Row>
							{logo ? (
								<Col className="collapse-brand" xs="6">
									{logo.innerLink ? (
										<Link to={logo.innerLink}>
											<img
												alt={logo.imgAlt}
												src={logo.imgSrc}
											/>
										</Link>
									) : (
										<a href={logo.outterLink}>
											<img
												alt={logo.imgAlt}
												src={logo.imgSrc}
											/>
										</a>
									)}
								</Col>
							) : null}
							<Col className="collapse-close" xs="6">
								<button
									className="navbar-toggler"
									type="button"
									onClick={toggleCollapse}
								>
									<span />
									<span />
								</button>
							</Col>
						</Row>
					</div>
					{/* Navigation */}
					<Nav navbar>{createLinks(routes)}</Nav>
					{/* Divider */}
					<hr className="my-3" />
					{/* Heading */}
					<h6 className="navbar-heading text-muted">Useful Links</h6>
					{/* Navigation */}
					<Nav className="mb-md-3" navbar>
						<NavItem>
							<NavLink href="/about">
								<i className="ni ni-spaceship mr-3" />
								About Us
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="/programs">
								<i className="ni ni-palette mr-3" />
								Programs
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink href="/projects">
								<i className="ni ni-ui-04 mr-3" />
								Projects
							</NavLink>
						</NavItem>
					</Nav>
				</Collapse>
			</Container>
		</Navbar>
	);
};

Sidebar.defaultProps = {
	routes: [{}],
};

Sidebar.propTypes = {
	// links that will be displayed inside the component
	routes: PropTypes.arrayOf(PropTypes.object),
	logo: PropTypes.shape({
		// innerLink is for links that will direct the user within the app
		// it will be rendered as <Link to="...">...</Link> tag
		innerLink: PropTypes.string,
		// outterLink is for links that will direct the user outside the app
		// it will be rendered as simple <a href="...">...</a> tag
		outterLink: PropTypes.string,
		// the image src of the logo
		imgSrc: PropTypes.string.isRequired,
		// the alt for the img
		imgAlt: PropTypes.string.isRequired,
	}),
};

export default Sidebar;
