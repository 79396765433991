import React from "react";
import ContentLoader from "react-content-loader";

const DueLoader = (props) => {
	return (
		<ContentLoader
			viewBox="0 0 100% 100%"
			height={"100%"}
			width={"100%"}
			{...props}
		>
			<rect x="0" y="42" rx="5" ry="5" width="100%" height="200" />
		</ContentLoader>
	);
};

export default DueLoader;
