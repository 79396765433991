import React from "react";
import { useParams } from "react-router-dom";
import { NormalLayout, HeaderMeta } from "../../layouts";
import PageTitle from "../../components/general/pagetitle";
import { ProgramSingle } from "../../components/sections/ProgramSinglePage";

const ProgramSinglePage = () => {
	const { id } = useParams();
	return (
		<NormalLayout>
			<HeaderMeta title={"Program"} />

			<PageTitle pageTitle={"Program"} pagesub={"Program"} />
			<ProgramSingle ID={id} />
		</NormalLayout>
	);
};

export default ProgramSinglePage;
