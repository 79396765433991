import React, { useEffect, useState } from "react";
import "./style.css";
import FullPageLoader from "../../../FullPageLoader";

const AboutDetails = (props) => {
	const [isMounted, setIsMounted] = useState(false)
	useEffect(()=> {
		setIsMounted(true)
	  }, [isMounted])
	// const ClickHandler = () =>{
	//     window.scrollTo(10, 0);
	// }

	return (
		isMounted ? 
		<div id="section-padding" className="wpo-about-area">
			<div className="container">
				<div className="">
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div className="wpo-about-img-3">
								<img src={props.aboutImg} alt="" />
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div className="wpo-about-text">
								<div className="wpo-section-title">
									<h2>What is Ramadan??</h2>
								</div>
								<p>
									{" "}
									Ramadan is a month of fasting and abstaining
									from things considered to be impure for the
									mind and body. Those partaking in Ramadan
									abstain from food, drink and impure thoughts
									between the hours of sunrise (Fajr) and
									sunset, allowing them instead to focus on
									prayer and connecting with Allah (SWT). The
									act of fasting allows the individual to
									understand the pain and suffering of
									millions around the world who live their
									lives in poverty and famine, leaving the
									participant feeling more grounded and
									grateful for all that Allah (SWT) has given
									them.
								</p>
							</div>
						</div>
						<div className="col-md-12 col-sm-12 wpo-about-text mt-3">
							<p>
								At the close of the month, Zakat donations
								during Ramadan are made and then Eid al-Fitr is
								celebrated with loved ones. Eid is a great time
								of feasting and celebration for Muslims, with
								gifts exchanged between loved ones.
							</p>

							<h3 className="header mt-5">OUR AIM</h3>
							{/* <ul className="list-group"> */}
							<p>
								For Muslims throughout the world, the sacred
								month of Ramadan is a time of great spiritual
								significance. Because it is celebrated during
								the ninth month of the Islamic lunar calendar,
								Ramadan begins at a slightly different time each
								year. This coming Ramadan might starts on the{" "}
								<b style={{ fontWeight: "bold" }}>
									11th March 2024
								</b>
								, and Muslims are required to fast during the
								daylight hours throughout the month. The
								challenge of{" "}
								<b style={{ fontWeight: "bold" }}>
									what to eat
								</b>{" "}
								is a big concern for{" "}
								<b style={{ fontWeight: "bold" }}>
									most students
								</b>{" "}
								in campus environment, our aim is to make{" "}
								<b style={{ fontWeight: "bold" }}>food free</b>{" "}
								and accessible to all fasting muslims' during
								the period of sahoor and iftar all through the
								days in the holy month of Ramadan.
							</p>
							{/* </ul> */}
						</div>
					</div>
				</div>
			</div>
		</div> : <FullPageLoader/>
	);
};

export default AboutDetails;
