import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Homepage from "./HomePage";
import AboutPage from "./AboutPage";
import RamadanPage from "./RamadanPage";
import IPWPage from "./IPW023Page";
import DawahCampPage from "./DawahCampPage";
import ProgramPage from "./ProgramPage";
import ProgramSinglePage from "./ProgramSinglePage";
import ProjectPage from "./ProjectPage";
import ProjectSinglePage from "./ProjectSinglePage";
import EventPage from "./EventPage";
import AcademicsPage from "./AcademicsPage";
import EventPageSingle from "./EventPageSingle";
import DonatePage from "./DonatePage";
import ContactPage from "./ContactPage";
import LoginPage from "./LoginPage";
import SignUpPage from "./SignUpPage";
import ForgotPassword from "./ForgotPassword";
import PaymentVerification from "./PaymentVerification";
import DueVerification from "./PaymentVerification/annual";
import AccountVerification from "./AccountVerification";
import HCDP from "./HCDP";
import MagazineDownload from "./MagazineDownload";
import Magazine from "./Magazine";
import PasswordResetPage from "./PasswordResetPage";
import Layout from "../layouts/DashboardLayout";
import ErrorPage from "./ErrorPage";
import AdminPage from "./Admin";

import MssnSuccessPayment from "./Ticket/ticketDetails"
import FullPageLoader from "../components/FullPageLoader";
// import PrivateRoute from "./Admin/privateRoute";

// Router
const AllRoute = () => {
	const [loading, setIsLoading] = useState()
	const token = JSON.parse(localStorage.getItem("token"));
	const userDetails = JSON.parse(localStorage.getItem("userAuthDetails"));
	const isAuthorised = userDetails?.role === "Admin" || userDetails?.role === "Protocol" ? true : false;
	console.log(userDetails?.role, isAuthorised)
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	useEffect(()=>{token?setIsAuthenticated(true):setIsAuthenticated(false)},[])
	
	return (
		<Router>
			<Switch>
				<Route exact path="/" component={Homepage} />
				<Route exact path="/home" component={Homepage} />
				<Route path="/about" component={AboutPage} />
				<Route path="/ramadan" component={RamadanPage} />
				<Route path="/ipw" component={IPWPage} />
				<Route path="/dawahcamp" component={DawahCampPage} />
				<Route path="/programs" component={ProgramPage} />
				<Route path="/program/:id" component={ProgramSinglePage} />
				<Route path="/projects" component={ProjectPage} />
				<Route path="/project/:id" component={ProjectSinglePage} />
				<Route path="/events" component={EventPage} />
				<Route path="/academics" component={AcademicsPage} />
				<Route path="/event/:id" component={EventPageSingle} />
				<Route path="/donate" component={DonatePage} />
				<Route path="/hcdp" component={HCDP} />
				<Route path="/magazine/download" component={MagazineDownload} />
				<Route path="/magazine" component={Magazine} />
				<Route path="/404" component={ErrorPage} />
				<Route path="/contact" component={ContactPage} />
				<Route path="/login" component={LoginPage} />
				<Route path="/signup" component={SignUpPage} />
				<Route path="/forgot-password" component={ForgotPassword} />
				<Route
					path="/donations/verify"
					component={PaymentVerification}
				/>
				<Route path="/payment/verify" component={DueVerification} />
				<Route
					path="/auth/email-confirmation"
					component={AccountVerification}
				/>
				<Route path="/success" component={ loading ? <FullPageLoader/> :  MssnSuccessPayment} />
				<Route
					path="/auth/reset-password"
					component={PasswordResetPage}
				/>
				{isAuthenticated && isAuthorised && <Route
					path="/admin/verifyticket"
					component={AdminPage}
				/>}
				<Route
					path="/dashboard"
					render={(props) => <Layout {...props} />}
				/>
				<Route component={ErrorPage} />
			</Switch>
		</Router>
	);
};

export default AllRoute;
