import React from "react";
import { NormalLayout, HeaderMeta } from "../../layouts";
import { IPW, About, Proposal } from "../../components/sections/IPWPage";
import { ipw2023Img } from "../../assets/images";

const IPWPage = () => {
	return (
		<NormalLayout>
			<HeaderMeta title={"Islamic Propagation Week"} />

			<IPW aboutImg={ipw2023Img} />
			<About />
			<Proposal />
		</NormalLayout>
	);
};
export default IPWPage;
