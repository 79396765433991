import React, { useState, useEffect } from "react";
import PaymentService from "../../../../services/PaymentService";
import { toast } from "react-toastify";
import { LoadingIndicator, pmt1, pmt2, pmt4 } from "../../../../assets/images";
import { Player } from "@lottiefiles/react-lottie-player";
import { useHistory } from "react-router-dom";

import "./style.css";

const Donate = (props) => {
	const history = useHistory();
	const [type, setType] = useState("card");
	const [email, setEmail] = useState("");
	const [fname, setFname] = useState("");
	const [lname, setLname] = useState("");
	const [phone, setPhone] = useState("");
	const [ramadan, setRamadan] = useState(false);
	const [message, setMessage] = useState("");
	const [amount, setAmount] = useState("");
	const [loader, setLoader] = React.useState(false);

	useEffect(() => {
		const ramadan = localStorage.getItem("ramadan");
		if (ramadan && history.location.state !== undefined) {
			const tier = JSON.parse(ramadan);
			setRamadan(true);
			setMessage("Ramadan " + tier.name + " - " + tier.desc);
			setAmount(
				tier.price.includes(",")
					? tier.price.split(",").join("")
					: tier.price
			);
		} else {
			localStorage.clear();
		}
	}, []);

	const SubmitHandler = (e) => {
		e.preventDefault();
		setLoader(true);
		PaymentService.donate({
			donor_firstname: fname,
			donor_lastname: lname,
			donor_email: email,
			donor_phone: phone,
			message: message,
			amount: amount,
			type: type,
		}).then(function (response) {
			if (response.flag) {
				if (type === "offline") {
					toast.success("Thanks for Donating!  Jazakallah Khair");
					history.push("/");
				} else {
					window.location.href = response.data.data.link;
				}
			} else {
				setLoader(false);
				toast.error(
					response && response.data && response.data.message
						? response.data.message
						: "Something went wrong. Try again"
				);
			}
		});
		setEmail("");
		setFname("");
		setLname("");
		setAmount("");
		setPhone("");
		setMessage("");
		localStorage.clear();
	};

	return (
		<div className="wpo-donation-page-area section-padding">
			<div className="container">
				<div className="row">
					<div className="col-lg-8 offset-lg-2">
						<div className="wpo-donate-header">
							<h2>Make a Donation</h2>
						</div>
						<form onSubmit={SubmitHandler} action="#">
							<div className="wpo-donations-amount">
								<h2>Your Donation</h2>
								<input
									type="number"
									className="form-control"
									name="amount"
									id="amount"
									placeholder="Enter Donation Amount"
									value={amount}
									onChange={(e) => setAmount(e.target.value)}
									required
									disabled={ramadan}
								/>
							</div>
							<div className="wpo-donations-details">
								<h2>Details</h2>
								<div className="row">
									<div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
										<input
											type="text"
											className="form-control"
											name="name"
											id="fname"
											placeholder="First Name"
											value={fname}
											onChange={(e) =>
												setFname(e.target.value)
											}
											required
										/>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
										<input
											type="text"
											className="form-control"
											name="name"
											id="name"
											placeholder="Last Name"
											value={lname}
											onChange={(e) =>
												setLname(e.target.value)
											}
											required
										/>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group clearfix">
										<input
											type="email"
											className="form-control"
											name="email"
											id="email"
											placeholder="Email"
											value={email}
											onChange={(e) =>
												setEmail(e.target.value)
											}
											required
										/>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
										<input
											type="text"
											className="form-control"
											name="Phone"
											id="Phone"
											placeholder="Phone"
											value={phone}
											onChange={(e) =>
												setPhone(e.target.value)
											}
											required
										/>
									</div>
									<div className="col-lg-12 col-12 form-group">
										<textarea
											className="form-control"
											name="note"
											id="note"
											value={message}
											onChange={(e) =>
												setMessage(e.target.value)
											}
											required
											disabled={ramadan}
											placeholder="Message"
										></textarea>
									</div>
								</div>
							</div>
							<div className="wpo-doanation-payment">
								<h2>Choose Your Payment Method</h2>
								<div className="wpo-payment-area">
									<div className="row">
										<div className="col-12">
											<div
												className="wpo-payment-option"
												id="open4"
											>
												<div className="wpo-payment-select">
													<ul>
														<li className="addToggle">
															<input
																id="add"
																type="radio"
																name="payment"
																checked={
																	type ===
																	"card"
																}
																value="card"
																onClick={() =>
																	setType(
																		"card"
																	)
																}
															/>
															<label htmlFor="add">
																Payment By Card
															</label>
														</li>
														<li className="removeToggle">
															<input
																id="remove"
																type="radio"
																name="payment"
																checked={
																	type ===
																	"offline"
																}
																value="offline"
																onClick={() =>
																	setType(
																		"offline"
																	)
																}
															/>
															<label htmlFor="remove">
																Offline Donation
															</label>
														</li>
													</ul>
												</div>
												{type === "card" ? (
													<div
														id="open5"
														className="payment-name"
													>
														<ul>
															<li className="visa">
																<input
																	id="1"
																	type="radio"
																	name="size"
																	value="30"
																/>
																<label htmlFor="1">
																	<img
																		src={
																			pmt1
																		}
																		alt=""
																	/>
																</label>
															</li>
															<li className="mas">
																<input
																	id="2"
																	type="radio"
																	name="size"
																	value="30"
																/>
																<label htmlFor="2">
																	<img
																		src={
																			pmt2
																		}
																		alt=""
																	/>
																</label>
															</li>
															<li className="pay">
																<input
																	id="4"
																	type="radio"
																	name="size"
																	value="30"
																/>
																<label htmlFor="4">
																	<img
																		src={
																			pmt4
																		}
																		alt=""
																	/>
																</label>
															</li>
														</ul>
													</div>
												) : (
													<div>
														<b>Pay to:</b>
														<p>
															<b>Account Name:</b>{" "}
															MSSN LASU EPE
														</p>
														<p>
															<b>
																Account Number:
															</b>{" "}
															2005818398
														</p>
														<p>
															<b>Bank:</b> First
															Bank
														</p>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="submit-area">
								<button
									type="submit"
									className="theme-btn submit-btn"
								>
									{loader ? (
										<div className="d-flex">
											Processing....
											<span className="d-inline">
												<Player
													loop="true"
													src={LoadingIndicator}
													style={{
														width: 18,
														marginLeft: 5,
													}}
													autoplay="true"
												/>
											</span>
										</div>
									) : (
										"Donate"
									)}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Donate;
