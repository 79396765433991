import React from "react";
import { Helmet } from "react-helmet";

const HeaderMeta = (props) => {
	const { title, description } = props;
	return (
		<Helmet>
			<meta charSet="utf-8" />
			<title>
				{title ? `${title} | MSSN LASU EPE` : "MSSN LASU EPE"}
			</title>
			<meta
				name="description"
				content={
					description &&
					"MSSN LASU EPE is an association for muslim students in LASU EPE Campus. We organize Spiritually uplifting programmes, Lectures and Usrah, career development programmes and tutorials, and Arabic classes"
				}
			/>
		</Helmet>
	);
};

export default HeaderMeta;
