import React, { useState } from "react";
import { HeaderMeta } from "../../layouts";
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Link, withRouter } from "react-router-dom";
import AuthService from "../../services/AuthService";
import { Player } from "@lottiefiles/react-lottie-player";
import { LoadingIndicator } from "../../assets/images";

import "./style.scss";

const ForgotPassword = (props) => {
	const [value, setValue] = useState({
		email: "",
		loader: false,
	});

	const changeHandler = (e) => {
		setValue({ ...value, [e.target.name]: e.target.value });
		validator.showMessages();
	};

	const [validator] = React.useState(
		new SimpleReactValidator({
			className: "text-danger",
		})
	);

	const submitForm = (e) => {
		e.preventDefault();
		if (validator.allValid()) {
			validator.hideMessages();
			setValue({ ...value, loader: true });
			AuthService.resetPasswordUpdate({ email: value.email }).then(
				function (response) {
					if (response.flag) {
						setValue({
							email: "",
							loader: false,
						});
						toast.success(
							"Reset Password Link Sent to Your Email."
						);
					} else {
						setValue({
							email: "",
							loader: false,
						});
						toast.error(
							response.message
								? response.message
								: "Something went wrong. Try again"
						);
					}
				}
			);
		} else {
			validator.showMessages();
			toast.error("Empty field is not allowed!");
		}
	};
	return (
		<>
			<HeaderMeta title={"Forgot Password"} />
			<Grid className="loginWrapper">
				<Grid className="loginForm">
					<h2>Forgot Password</h2>
					<p>Reset your account password</p>
					<form onSubmit={submitForm}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<TextField
									className="inputOutline"
									fullWidth
									placeholder="E-mail"
									value={value.email}
									variant="outlined"
									name="email"
									label="E-mail"
									InputLabelProps={{
										shrink: true,
									}}
									onBlur={(e) => changeHandler(e)}
									onChange={(e) => changeHandler(e)}
								/>
								{validator.message(
									"email",
									value.email,
									"required|email"
								)}
							</Grid>
							<Grid item xs={12}>
								<Grid className="formFooter">
									<Button
										fullWidth
										className="cBtn cBtnLarge cBtnTheme"
										type="submit"
									>
										{value.loader ? (
											<span className="d-inline-flex">
												Sending Reset Link...{" "}
												<Player
													loop="true"
													src={LoadingIndicator}
													style={{
														width: 18,
														marginLeft: 5,
													}}
													autoplay="true"
												/>
											</span>
										) : (
											"Reset Password"
										)}
									</Button>
								</Grid>
								<p className="noteHelp">
									Already have an account?{" "}
									<Link to="/login">Return to Sign In</Link>
								</p>
							</Grid>
						</Grid>
					</form>
				</Grid>
			</Grid>
		</>
	);
};

export default withRouter(ForgotPassword);
