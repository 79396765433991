import React from 'react';
import styles from './TicketDetails.module.css'; // Import your CSS file for styling
import Modal from '../modal/modal';
import PaymentStatus from './../paymentstatus/status';


const TicketDetails = ({ ticketDetails,modalShow,modShow,backToId,message,error }) => {
  return (<>
    <Modal>
        <div className={styles.ticketContainer}>
        <h2>Ticket Details</h2>
        <div className={styles.ticketInfo}>
            <p><strong>TICKET ID:</strong> {ticketDetails._id}</p>
            <p><strong>Purchased By:</strong> {ticketDetails.purchasedBy}</p>
            <p><strong>Email:</strong> {ticketDetails.email}</p>
            <p><strong>Is Ticket Used:</strong> {ticketDetails.isTicketUsed ? 'Yes' : 'No'}</p>
            {ticketDetails.isTicketUsed && (
            <p><strong>Used At:</strong> {new Date(ticketDetails.usedAt).toLocaleString()}</p>
            )}
            <p><strong>Updated At:</strong> {new Date(ticketDetails.updatedAt).toLocaleString()}</p>
        </div>
        <div className={styles.button} onClick={modalShow}>ok</div>
        </div>
    </Modal>
    {modShow && <PaymentStatus action={backToId} message={message} error={error}/>}
    </>
  );
};

export default TicketDetails;
