import React from "react";
import "./style.css";
import { useFileDownload } from "../../../hooks/useFileDownload";

const DawahRegister = (props) => {
	const { downloadFile } = useFileDownload("/The Guide Magazine FV.pdf", {
		downloadOnLoad: false,
	});

	return (
		<section
			id="call-to-action"
			class="gradient-to-right py-3 my-3 text-light call-to-action"
			aria-label="cta"
		>
			<div class="container py-3 my-3">
				<div class="row align-items-center">
					<div class="col-lg-8 col-md-7">
						<h3 class="size-20 no-margin" style={{ color: "white" }}>
							Do you want to be a Vicegerent?
						</h3>
					</div>

					<div class="col-lg-4 col-md-5 text-right">
						<a href="https://forms.gle/ANJXkaMmipj5SY7V8">
                            <button
							class="btn-custom rounded-lg py-3 text-white scroll-to"
						>
							Register Now!
						</button></a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default DawahRegister;
