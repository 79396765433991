import React from "react";
import { NormalLayout, HeaderMeta } from "../../layouts";
import PageTitle from "../../components/general/pagetitle";
import { Donate } from "../../components/sections/DonatePage";

const DonatePage = () => {
	return (
		<NormalLayout>
			<HeaderMeta title={"Donate"} />

			<PageTitle pageTitle={"Donate Now"} pagesub={"Donate"} />
			<Donate />
		</NormalLayout>
	);
};
export default DonatePage;
