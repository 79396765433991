import React from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";

const Carousel2 = ({ images, style }) => {
	return (
		<section className="section" aria-labelledby="example2" style={style}>
			<div className="carousel-container d-flex justify-content-center">
				<Carousel images={images} style={{ height: 500, width: 800 }} />
			</div>
		</section>
	);
};

export default Carousel2;
