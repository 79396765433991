import axios from "axios";
import env from "../env.json";

const PaymentService = {
	async donate(data) {
		return await axios
			.post(env.API_URL + "donations", data)
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;
				return response;
			})
			.catch(function (error) {
				return error.response.data;
			});
	},

	async verifyDonate(data) {
		return await axios
			.post(env.API_URL + "donations/verify", data)
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;
				return response;
			})
			.catch(function (error) {
				return error.response.data;
			});
	},

	async duePayment() {
		return await axios
			.post(
				env.API_URL + "payments",
				{},
				{
					headers: localStorage.getItem("userAuthDetails")
						? {
								Authorization: `Bearer ${
									JSON.parse(
										localStorage.getItem("userAuthDetails")
									).jwt
								}`,
						  }
						: {},
				}
			)
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;

				return response;
			})
			.catch(function (error) {
				return error.response.data;
			});
	},

	async verifyDuePayment(data) {
		return await axios
			.post(env.API_URL + "payments/verify", data)
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;
				return response;
			})
			.catch(function (error) {
				return error.response.data;
			});
	},

	//Fetch Dues
	async fetchDues() {
		return await axios
			.get(env.API_URL + "dues")
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;
				return response;
			})
			.catch(function (error) {
				return error.response.data;
			});
	},

	//Fetch Payment History
	async fetchPaymentHistoryList(data) {
		return await axios
			.get(
				env.API_URL +
					`payments?payment_type=Annual Due&status=successful&user_id=${data}`
			)
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;
				return response;
			})
			.catch(function (error) {
				return error.response.data;
			});
	},
};

export default PaymentService;
