import React from "react";
import { useParams } from "react-router-dom";
import { NormalLayout, HeaderMeta } from "../../layouts";
import PageTitle from "../../components/general/pagetitle";
import { ProjectSingle } from "../../components/sections/ProjectSinglePage";

const ProjectSinglePage = () => {
	const { id } = useParams();

	return (
		<NormalLayout>
			<HeaderMeta title={"Project"} />

			<PageTitle pageTitle={"Project"} pagesub={"Project"} />
			<ProjectSingle ID={id} />
		</NormalLayout>
	);
};

export default ProjectSinglePage;
