import axios from "axios";
import env from "../env.json";

const ExcoService = {
	//Fetch Exco Items
	async fetchExcosList() {
		return await axios
			.get(env.API_URL + "excos?_sort=order:ASC")
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;
				return response;
			})
			.catch(function (error) {
				return error;
			});
	},
};

export default ExcoService;
