import React, { useEffect } from "react";
import { NormalLayout, HeaderMeta } from "../../layouts";
import { useFileDownload } from "../../hooks/useFileDownload";

const MagazineDownload = () => {
	const { downloadFile } = useFileDownload("/The Guide Magazine FV.pdf", {
		downloadOnLoad: true,
	});

	return (
		<NormalLayout>
			<HeaderMeta title={"MAGAZINE DOWNLOAD"} />

			{/* <PageTitle
				pageTitle={"MAGAZINE INTEREST FORM"}
				// pagesub={"Membership Registration Form"}
			/> */}
			<div className="magazine-download container py-5">
				<div className="header">
					<h1>The Guide</h1>

					<div>
						<div className="line"></div>
						<span>VOL. 1</span>
					</div>
				</div>
				<h2>Theme: Living above the bottom line</h2>

				<div className="content">
					<ul>
						<li>Rising above the bottom line as a muslim</li>
						<li>
							Navigating Graduate School Applications in the
							United States
						</li>
						<li>Success on the path to knowledge</li>
					</ul>
					<button
						className="theme-button mt-4"
						onClick={downloadFile}
					>
						download
					</button>
				</div>
			</div>
		</NormalLayout>
	);
};

export default MagazineDownload;
