import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../../services/AuthService";
import { toast } from "react-toastify";
import "./style.css";

const HeaderTopbar = () => {
	const [isLoggedIn, setIsLoggedIn] = React.useState(false);

	useEffect(() => {
		AuthService.checkUserSession().then(function (response) {
			if (!response) {
				setIsLoggedIn(false);
			} else {
				AuthService.getUserProfile().then(function (response) {
					if (response.isExpired) {
						userLogout();
					} else {
						setIsLoggedIn(true);
					}
				});
			}
		});
	}, []);

	const userLogout = () => {
		AuthService.userLogout().then(function (response) {
			if (response) {
				toast.error("Your session has expired. Re-login to continue.");
				window.location.replace("/login");
			}
		});
	};

	return (
		<div className="topbar">
			<div className="container">
				<div className="row">
					<div className="col col-md-6 col-sm-12 col-12">
						<div className="contact-intro">
							<ul>
								<li>
									<i className="fi flaticon-call"></i>+234
									903-146-1460
								</li>
								<li>
									<i className="fi flaticon-envelope"></i>{" "}
									info@mssnlasuepe.com.ng
								</li>
							</ul>
						</div>
					</div>
					<div className="col col-md-6 col-sm-12 col-12">
						<div className="contact-info">
							<ul>
								{!isLoggedIn ? (
									<>
										<li>
											<Link to="/login">Login</Link>
										</li>
										<li>
											<Link to="/signup">Sign Up</Link>
										</li>
									</>
								) : (
									<>
										<li>
											<Link to="/dashboard">
												Dashboard
											</Link>
										</li>
										<li>
											<Link to="/dashboard/user-profile">
												My Profile
											</Link>
										</li>
									</>
								)}
								<li>
									<Link className="theme-btn" to="/donate">
										Donate Now
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeaderTopbar;
