import React from "react";
// reactstrap components
import { Container, Row } from "reactstrap";
import "./style.css";

const UserHeader = () => {
	return (
		<div
			className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
			style={{
				minHeight: "100px",
				backgroundImage:
					"url(" +
					require("../../../assets/img/theme/profile-cover.jpg")
						.default +
					")",
				backgroundSize: "cover",
				backgroundPosition: "center top",
			}}
		>
			{/* Mask */}
			<span className="mask bg-gradient-infos opacity-8" />
			{/* Header container */}
			<Container className="d-flex align-items-center" fluid>
				<Row></Row>
			</Container>
		</div>
	);
};

export default UserHeader;
