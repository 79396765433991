import React, { Component } from "react";
import EmailService from "../../../../../services/EmailService";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { Player } from "@lottiefiles/react-lottie-player";
import { LoadingIndicator } from "../../../../../assets/images";

class ContactForm extends Component {
	state = {
		name: "",
		email: "",
		subject: "",
		lastname: "",
		message: "",
		captcha: "",
		loader: false,
		error: {},
	};

	changeHandler = (e) => {
		const error = this.state.error;
		error[e.target.name] = "";

		this.setState({
			[e.target.name]: e.target.value,
			error,
		});
	};

	onChange = () => {
		const error = this.state.error;
		error["captcha"] = "";

		this.setState({
			captcha: "solved",
			error,
		});
	};

	submitHandler = (e) => {
		e.preventDefault();

		const { name, email, subject, lastname, message, error, captcha } =
			this.state;

		if (name === "") {
			error.name = "Please enter your first name";
		}
		if (email === "") {
			error.email = "Please enter your email";
		}
		if (subject === "") {
			error.subject = "Please enter your subject";
		}
		if (lastname === "") {
			error.lastname = "Please enter your Last name";
		}
		if (message === "") {
			error.message = "Please enter your message";
		}
		if (captcha === "") {
			error.captcha = "Please complete the Captcha";
		}

		if (error) {
			this.setState({
				error,
			});
		}

		const data = {
			firstName: name,
			lastName: lastname,
			email: email,
			subject: subject,
			message: message,
		};

		if (
			error.name === "" &&
			error.email === "" &&
			error.message === "" &&
			error.lastname === "" &&
			error.subject === "" &&
			error.captcha === ""
		) {
			this.setState({
				loader: true,
				error: {},
			});

			EmailService.sendEmail(data).then(function (response) {
				if (response.flag) {
					toast.success("Email Sent successfully");
					window.location.reload();
				} else {
					this.setState({
						loader: false,
					});
					toast.error(
						response && response.data && response.data.message
							? response.data.message
							: "Something went wrong. Try again"
					);
				}
			});
		}
	};

	render() {
		const { name, email, subject, lastname, message, error, loader } =
			this.state;

		return (
			<form onSubmit={this.submitHandler} className="form">
				<div className="row">
					<div className="col-lg-6 col-md-12">
						<div className="form-field">
							<input
								value={name}
								onChange={this.changeHandler}
								type="text"
								name="name"
								placeholder="First name"
							/>
							<p>{error.name ? error.name : ""}</p>
						</div>
					</div>
					<div className="col-lg-6 col-md-12">
						<div className="form-field">
							<input
								value={lastname}
								onChange={this.changeHandler}
								type="text"
								name="lastname"
								placeholder=" Last name"
							/>
							<p>{error.lastname ? error.lastname : ""}</p>
						</div>
					</div>
					<div className="col-lg-12">
						<div className="form-field">
							<input
								onChange={this.changeHandler}
								value={email}
								type="email"
								name="email"
								placeholder="Email"
							/>
							<p>{error.email ? error.email : ""}</p>
						</div>
					</div>
					<div className="col-lg-12">
						<div className="form-field">
							<input
								onChange={this.changeHandler}
								value={subject}
								type="text"
								name="subject"
								placeholder="Subject"
							/>
							<p>{error.subject ? error.subject : ""}</p>
						</div>
					</div>
					<div className="col-lg-12">
						<div className="form-field">
							<textarea
								name="message"
								onChange={this.changeHandler}
								value={message}
								placeholder="Message"
							></textarea>
							<p>{error.message ? error.message : ""}</p>
						</div>
					</div>
					<div className="col-lg-12">
						<ReCAPTCHA
							sitekey="6Lf5R_gbAAAAAGESEJpmlCPeSLlLzqww3big36bU"
							onChange={this.onChange}
						/>
						<p>{error.captcha ? error.captcha : ""}</p>
					</div>
					<div className="col-lg-12">
						<div className="form-submit">
							<button type="submit" className="theme-btn d-flex">
								{loader ? (
									<span className="d-inline-flex">
										Sending...{" "}
										<Player
											loop="true"
											src={LoadingIndicator}
											style={{ width: 18, marginLeft: 5 }}
											autoplay="true"
										/>
									</span>
								) : (
									"Send Message"
								)}
							</button>
						</div>
					</div>
				</div>
			</form>
		);
	}
}
export default ContactForm;
