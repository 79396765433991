import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import VideoModal from "../../AboutPage/AboutDetails/ModalVideo";
import axios from "axios";
import { tmr1, tmr2, tmr3 } from "../../../../assets/images";

import "./style.css";

const About = (props) => {
	let [prayer, setPrayer] = React.useState(null);

	useEffect(() => {
		prayerTimes();
	}, []);

	const ClickHandler = () => {
		window.scrollTo(10, 0);
	};

	const prayerTimes = () => {
		axios({
			method: "GET",
			url: "https://api.aladhan.com/v1/timingsByAddress?address=Lagos",
		})
			.then((response) => {
				console.log(response.data.data.timings);
				setPrayer(response.data.data);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<div className="wpo-about-area section-padding">
			<div className="container">
				<div className="wpo-about-wrap">
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-12">
							<div className="wpo-about-img-3">
								<img src={props.aboutImg} alt="" />
							</div>
						</div>
						<div className="col-lg-6 col-md-12 colsm-12">
							<div className="wpo-about-text">
								<div className="wpo-section-title">
									<span>About Us</span>
									<h2>
										Two decades unifying all Muslim students
										in Lagos State University, Epe Campus
									</h2>
								</div>
								<p>
									The Muslim Students' Society of Nigeria,
									Lagos State University, Epe Campus is an
									institutional branch of the Lagos State Area
									Unit entrusted with the task of bringing all
									Muslims into close union and inculcating in
									them the true Islamic spirit of brotherhood
									and absolute faith in Allah as the only
									basis for the achievement of peace among
									mankind.
								</p>
								<div className="btns">
									<Link
										onClick={ClickHandler}
										to="/about"
										className="theme-btn"
										tabIndex="0"
									>
										Discover More
									</Link>
									<ul>
										<li className="video-holder">
											<VideoModal />
										</li>
										<li className="video-text">
											Watch Our Video
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				{prayer && (
					<div className="timer-section">
						<div className="row">
							<div className="col-lg-5">
								<div className="timer-text">
									<h2>Prayer Times</h2>
									<p>Prayer times in Nigeria</p>
									<span>
										{prayer.date.hijri.weekday.en}{" "}
										{prayer.date.hijri.day}{" "}
										{prayer.date.hijri.month.en},{" "}
										{prayer.date.hijri.year}
									</span>
								</div>
							</div>
							<div className="col-lg-5 offset-lg-1">
								<div className="timer-num">
									<ul>
										<li>
											Fajr
											<span>{prayer.timings.Fajr}</span>
										</li>
										<li>
											Sunrise
											<span>
												{prayer.timings.Sunrise}
											</span>
										</li>
										<li>
											Dhuhr
											<span>{prayer.timings.Dhuhr}</span>
										</li>
										<li>
											Asr<span>{prayer.timings.Asr}</span>
										</li>
										<li>
											Maghrib
											<span>
												{prayer.timings.Maghrib}
											</span>
										</li>
										<li>
											Isha
											<span>{prayer.timings.Isha}</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="timer-shape1">
							<img src={tmr1} alt="" />
						</div>
						<div className="timer-shape2">
							<img src={tmr2} alt="" />
						</div>
						<div className="timer-shape3">
							<img src={tmr3} alt="" />
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default About;
