import React from "react";
import { NormalLayout, HeaderMeta } from "../../layouts";
import { PageTitle, EventSection2 } from "../../components/sections/EventPage";

const EventPage = () => {
	return (
		<NormalLayout>
			<HeaderMeta title={"Events"} />

			<PageTitle pageTitle={"Our Events"} pagesub={"Events"} />
			<EventSection2 EventClass={"wpo-event-area-2"} />
		</NormalLayout>
	);
};

export default EventPage;
