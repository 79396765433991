import React from "react";
import { NormalLayout, HeaderMeta } from "../../layouts";
import PageTitle from "../../components/general/pagetitle";

const ContactPage = () => {
	return (
		<NormalLayout>
			<HeaderMeta title={"HCDP Membership Registration Form"} />

			<PageTitle
				pageTitle={"HCDP"}
				pagesub={"Membership Registration Form"}
			/>
			<div className="d-flex justify-content-center">
				<iframe
					title="HCDP Form"
					src="https://docs.google.com/forms/d/e/1FAIpQLSfqMDzXIZ5f9OvsJXvJz2qIAMwL6PVbDIloKNiCYhdnMmS0Gg/viewform?embedded=true"
					width="700"
					height="1900"
					frameborder="0"
					marginheight="0"
					marginwidth="0"
				>
					Loading…
				</iframe>
			</div>
		</NormalLayout>
	);
};
export default ContactPage;
