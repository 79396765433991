import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import AuthService from "../../services/AuthService";
import { toast } from "react-toastify";
// reactstrap components
import { Container } from "reactstrap";
// core components
import { Helmet } from "react-helmet";
import AdminNavbar from "./Navbars/AdminNavbar";
import Sidebar from "./Sidebar/Sidebar.js";
import AdminFooter from "./Footers/AdminFooter.js";
import { Logo } from "../../assets/images";
// import env from "../../env.json";
import routes from "./routes.js";

const Admin = (props) => {
	const mainContent = React.useRef(null);
	const location = useLocation();
	let [user, setUser] = React.useState({});
	let [token, setToken] = React.useState("");

	React.useEffect(() => {
		checkUserSession();
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
	}, [location]);

	const checkUserSession = () => {
		AuthService.getUserProfile().then(function (response) {
			if (response.isExpired) {
				AuthService.userLogout().then(function () {
					window.location.replace("/login");
				});
				toast.error("Your session has expired. Re-login to continue.");
			} else {
				setUser(response);
				setToken(response.jwt);
			}
		});
	};

	const getBrandText = (path) => {
		for (let i = 0; i < routes.length; i++) {
			if (
				props.location.pathname.indexOf(
					routes[i].layout + routes[i].path
				) !== -1
			) {
				return routes[i].name;
			}
		}
		return "Brand";
	};

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Dashboard | MSSN LASU EPE</title>
				<link
					rel="stylesheet"
					type="text/css"
					href="https://mssnlasuepe.com.ng/css/argon-dashboard-react.css"
				/>
				<link
					rel="stylesheet"
					type="text/css"
					href="https://mssnlasuepe.com.ng/css/nucleo.css"
				/>
			</Helmet>

			<Sidebar
				{...props}
				routes={routes}
				user={user}
				logo={{
					innerLink: "/dashboard/index",
					imgSrc: Logo,
					imgAlt: "...",
				}}
			/>

			<div className="main-content" ref={mainContent}>
				<AdminNavbar
					{...props}
					brandText={getBrandText(props.location.pathname)}
					user={user}
				/>

				<Switch>
					{routes.map((prop, key) => {
						const { path } = prop;

						return (
							<Route
								key={key}
								path={`/dashboard${path}`}
								component={() => <prop.component user={user} />}
							/>
						);
					})}

					<Redirect from="*" to="/dashboard/index" />
				</Switch>

				<Container fluid>
					<AdminFooter />
				</Container>
			</div>
		</>
	);
};

export default Admin;
