import axios from "axios";
import env from "../env.json";

const EmailService = {
	async sendEmail(data) {
		return await axios
			.post(env.API_URL + "sendmail", data)
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;
				return response;
			})
			.catch(function (error) {
				return error;
			});
	},

	async subscribe(data) {
		return await axios
			.post(env.API_URL + "newsletter", data)
			.then(function (response) {
				response.flag = parseInt(response.status) === 200;
				return response;
			})
			.catch(function (error) {
				return error;
			});
	},
};

export default EmailService;
