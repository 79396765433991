import React from "react";
import { NormalLayout, HeaderMeta } from "../../layouts";
import PageTitle from "../../components/general/pagetitle";
import { Error } from "../../components/sections/ErrorPage";

const ErrorPage = () => {
	return (
		<NormalLayout>
			<HeaderMeta title={"Error"} />

			<PageTitle pageTitle={"404"} pagesub={"404"} />
			<Error />
		</NormalLayout>
	);
};
export default ErrorPage;
