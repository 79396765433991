import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

const Support = (props) => {
	const ClickHandler = () => {
		window.scrollTo(10, 0);
	};

	return (
		<div className="mb-2">
			<div className="container">
				<div className="row">
					<div className="col-lg">
						<div className="support-text">
							<h1>Introducing...</h1>
							<h2>Project “Feed a Soul, Earn Rewards”</h2>
							<p>
								<i>
									<strong>
										The Prophet (saw) said, 'Whoever feeds a
										person breaking his fast will earn the
										same reward as him, without anything
										being lessened from the reward of the
										fasting person'.
									</strong>
								</i>
								<b>(Tirmidhi)</b>
							</p>
							<div className="btns">
								<Link
									onClick={ClickHandler}
									to="/donate"
									className="theme-btn-s3"
								>
									Donate Now
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Support;
