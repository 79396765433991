import React from "react";
import { useParams } from "react-router-dom";
import { NormalLayout, HeaderMeta } from "../../layouts";
import PageTitle from "../../components/general/pagetitle";
import { EventSingle } from "../../components/sections/EventPageSingle";

const EventPageSingle = () => {
	const { id } = useParams();

	return (
		<NormalLayout>
			<HeaderMeta title={"Event"} />

			<PageTitle pageTitle={"Event"} pagesub={"Event"} />
			<EventSingle ID={id} />
		</NormalLayout>
	);
};
export default EventPageSingle;
