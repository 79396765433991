import React from "react";
import { NormalLayout, HeaderMeta } from "../../layouts";
import { DawahCamp, About, DawahRegister } from "../../components/sections/DAWAHCAMP";
import { dawahcamp24Img } from "../../assets/images";

const DawahCampPage = () => {
	return (
		<NormalLayout>
			<HeaderMeta title={"Dawah Camp"} />
			<DawahCamp aboutImg={dawahcamp24Img} />
			<About />	
			<DawahRegister/>	
		</NormalLayout>
	);

};
export default DawahCampPage;
