import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./style.css";
import { toast } from "react-toastify";
import env from "../../../../env.json";
import axios from "axios";
import FullPageLoader from "../../../FullPageLoader";
import CircularProgress from '@material-ui/core/CircularProgress';


const ramadanTiers = [
	{
		name: "GOLD TIER",
		price: "900,000",
		desc: "Feed 60 fasting Muslims for 30 days",
	},
	{
		name: "SILVER TIER",
		price: "450,000",
		desc: "Feed 30 fasting Muslims for 30 days",
	},
	{
		name: "BRONZE TIER",
		price: "225,000",
		desc: "Feed 15 fasting Muslims for 30 days",
	},
	{
		name: "BASIC TIER",
		price: "15,000",
		desc: "Feed a fasting Muslims for 30 days",
	},
];


const ramadanTickets = [
	{
		name: "Iftar Ticket",
		price: 300,
		slug: "Iftar Ticket"
	},
	{
		name: "Sahur Ticket",
		price: 300,
		slug: "Sahur Ticket"

	},
]

const RamadanPayment = (props) => {

	const [isMounted, setIsMounted] = useState(false)
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const userDetails = JSON.parse(localStorage.getItem("userAuthDetails"));

	const handlePay = (tier) => {
		localStorage.setItem("ramadan", JSON.stringify(tier));
		const location = {
			pathname: "/donate",
			state: { fromRamadan: true },
		};
		history.push(location);
		window.scrollTo(10, 0);
	};
	useEffect(()=> {
		setIsMounted(true)
	})

	const purchaseTicket = async (ticketName) => {
		try {
			const ticket = await axios.post(env.API_URL + "buy-tickets/new", {
				ticketName,
				email: userDetails?.email,
				level: userDetails?.level,
				purchasedBy: userDetails?._id,
			})
			localStorage.setItem("ticketDetails", JSON.stringify(ticket?.data));
			toast.success(ticket.data?.message)
			window.location.href = "/ticket"
			return ticket
		} catch (error) {
			console.log(error);
			return toast.error(error.response?.data?.message)
		} finally {
			setIsLoading(false);
		}
	}
	
	return (
		isMounted ?
			<>
				{isLoading && (
					<div className="overlay-loader">
						<CircularProgress color="secondary" />
					</div>
				)} 
				<div id="" className="wpo-about-area">
					<div className="container">
						<div className="">
							<div className="row">
								<div className="col-md-12 col-sm-12 wpo-about-text mb-3">
									<h3 className="header mt-5">
										LONG TERM IMPACT
									</h3>
									<ul className="list-group">
										<p>
											The project alleviates the sufferings of
											over 400 beneficiaries. Hunger is
											alleviated and healthier life amoung the
											people. Islamic morals and the faith of
											the beneficiaries are also strengthened.
										</p>
									</ul>
									<h3 className="header mt-3">
										BE A PART OF THIS PROJECT
									</h3>
									<ul className="list-group">
										<p>
											We are making it easy to earn great
											rewards this Ramadan! With just #300 you
											can feed a fasting muslim this ramadan.
										</p>
									</ul>
								</div>
							<div className="flex flex-col gap-[20px]">
								<div class="row d-flex flex-wrap justify-content-center mx-3">
									{ramadanTiers.map((tier) => (
										<div class="mr-3 mb-3 flex-grow-1">
											<div class="card">
												<h4 class="card-header">
													{tier.name}
												</h4>
												<div class="card-body">
													<h4>NGN {tier.price}</h4>
													{tier.desc}
													<p>
														<a
															onClick={() =>
																handlePay(tier)
															}
															class="mt-2 float-right btn btn-outline-success"
															href="/donate"
														>
															Pay Now
														</a>
													</p>
												</div>
											</div>
										</div>
									))}
								</div>
									<h3 className="header mt-3">
										RAMADAN TICKETS
									</h3>
									<div style={{
										display: "flex",
										margin: "0 auto"

									}}>
										{
											ramadanTickets.map((rmdTicket, i)=> {
												return (

													<div class="card" key={i} style={{width: "100%"}}>
												<h4 class="card-header">
													{rmdTicket.name}
												</h4>
												<div class="card-body">
													<h4>NGN {rmdTicket.price}</h4>
													<p>
														<button
															onClick={
																async () => {
																	if (JSON.parse(localStorage.getItem("token"))) {
																		localStorage.setItem("ticketName", JSON.stringify(rmdTicket.slug));
																		setIsLoading(true);
																		await purchaseTicket(JSON.parse(localStorage.getItem("ticketName")));
																	} else {
																		window.location.href = "/login";
																	}
																}
															}
											                                disabled={true}
															class="mt-2 float-right btn btn-outline-success"
														>
															BUY NOW
														</button>
													</p>
												</div>
											</div>
												)
											})
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<section
					id="call-to-action"
					class="gradient-to-right py-3 my-3 mt-5 text-light call-to-action"
					aria-label="cta"
				>
					<div class="container py-3 my-3">
						<div class="row align-items-center">
							<div class="col-lg-8 col-md-7">
								<h3 class="size-2 no-margin text-white ">
									Want to Know more?
								</h3>
							</div>

							<div class="col-lg-4 col-md-5 text-right">
								<a
									href="./proposal.pdf"
									target="_blank"
									class="btn-custom text-white scroll-to py-3"
								>
									Download Proposal
								</a>
							</div>
						</div>
					</div>

				</section>
			</> 
		: 
		<FullPageLoader/>
	);
};

export default RamadanPayment;
