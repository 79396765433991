import React from "react";
import { NormalLayout, HeaderMeta } from "../../layouts";
import PageTitle from "../../components/general/pagetitle";
import { Contactpage } from "../../components/sections/ContactPage";

const ContactPage = () => {
	return (
		<NormalLayout footerClass={"wpo-ne-footer-2"}>
			<HeaderMeta title={"Contact Us"} />

			<PageTitle pageTitle={"Contact Us"} pagesub={"Contact"} />
			<Contactpage />
		</NormalLayout>
	);
};
export default ContactPage;
