import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AuthService from "../../services/AuthService";
import { Player } from "@lottiefiles/react-lottie-player";
import { LoadingIndicator } from "../../assets/images";
import { Link, withRouter } from "react-router-dom";

import "./style.scss";

const LoginPage = (props) => {
	const [value, setValue] = useState({
		email: "",
		password: "",
		loader: false,
	});

	const changeHandler = (e) => {
		setValue({ ...value, [e.target.name]: e.target.value });
		validator.showMessages();
	};

	const [validator] = React.useState(
		new SimpleReactValidator({
			className: "text-danger",
		})
	);

	const submitForm = (e) => {
		e.preventDefault();

		if (validator.allValid()) {
			validator.hideMessages();
			setValue({ ...value, loader: true });
			AuthService.authLogin(value).then(function (response) {
				if (response?.flag) {
					setValue({
						email: "",
						password: "",
					});
					toast.success("Login Successful");
					// props.history.push("/dashboard");
					props.history.push("/ramadan");
				} else {
					console.log(response)
					// if (response.id === "Auth.form.error.confirmed") {
					// 	AuthService.resendUserActivation({
					// 		email: value.email,
					// 	}).then(function (response) {
					// 		if (response.flag) {
					// 			toast.info(
					// 				"Email is not verified. An activation link sent to your email."
					// 			);
					// 			setValue({
					// 				email: "",
					// 				password: "",
					// 				loader: false,
					// 			});
					// 		} else {
					// 			toast.error(
					// 				response.message
					// 					? response.message
					// 					: "Something went wrong. Try again"
					// 			);
					// 		}
					// 	});
					// } else if (response.id === "Auth.form.error.invalid") {
					// 	setValue({ ...value, loader: false });
					// 	toast.error("Invalid Credentials");
					// } else {
					// 	if (response.data[0].messages[0]) {
					// 		toast.error(response.data[0].messages[0].message);
					// 	} else {
					// 	}
					// }
					// toast.error("Something went wrong. Try again");
					toast.error(response?.data?.message);
				}
			});
		} else {
			validator.showMessages();
			toast.error("Empty field is not allowed!");
		}
	};
	return (
		<div className="loginWrapper">
			<div className="loginForm">
				<div className="header text-center">
					<h2>Sign In</h2>
					<p>Sign in to your account</p>
				</div>

				<form onSubmit={submitForm}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<TextField
								className="inputOutline"
								fullWidth
								placeholder="E-mail"
								value={value.email}
								variant="outlined"
								name="email"
								label="E-mail"
								InputLabelProps={{
									shrink: true,
								}}
								onBlur={(e) => changeHandler(e)}
								onChange={(e) => changeHandler(e)}
							/>

							<p className="error-msg">
								{validator.message(
									"email",
									value.email,
									"required|email"
								)}
							</p>
						</Grid>
						<Grid item xs={12}>
							<TextField
								className="inputOutline"
								fullWidth
								placeholder="Password"
								value={value.password}
								variant="outlined"
								name="password"
								type="password"
								label="Password"
								InputLabelProps={{
									shrink: true,
								}}
								onBlur={(e) => changeHandler(e)}
								onChange={(e) => changeHandler(e)}
							/>

							<p className="error-msg">
								{validator.message(
									"password",
									value.password,
									"required"
								)}
							</p>
						</Grid>
						<Grid item xs={12}>
							<Grid className="formAction">
								<Link to="/forgot-password">
									Forgot Password?
								</Link>
							</Grid>
							<Grid className="formFooter">
								<Button
									fullWidth
									className="cBtnTheme"
									type="submit"
								>
									{value.loader ? (
										<span className="d-inline-flex">
											Logging in...{" "}
											<Player
												loop="true"
												src={LoadingIndicator}
												style={{
													width: 18,
													marginLeft: 5,
												}}
												autoplay="true"
											/>
										</span>
									) : (
										"Login"
									)}
								</Button>
							</Grid>
							<p className="noteHelp">
								Don't have an account?{" "}
								<Link to="/signup">Become a member</Link>
							</p>
							<p className="text-center">
								Go back <Link to="/">Home</Link>
							</p>
						</Grid>
					</Grid>
				</form>
			</div>
		</div>
	);
};

export default withRouter(LoginPage);
