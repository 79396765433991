import axios from "axios";
import env from "../../src/env.json";
import { toast } from "react-toastify";

const token = JSON.parse(localStorage.getItem("token"));
const AuthService = {

	// User Authentication
	async authLogin(data) {
		return await axios
			.post(env.API_URL + "user/login", {
				email: data.email,
				password: data.password,
			})
			.then(function (response) {
				if (parseInt(response.status) === 200) {
					
					localStorage.removeItem("userAuthDetails");
					response.flag = true;
					localStorage.setItem(
						"userAuthDetails",
						JSON.stringify(response?.data?.user)
					);
					localStorage.setItem(
						"token",
						JSON.stringify(response?.data?.token)
					);
				} else {
					response.flag = false;
				}
				return response;
			})
			.catch(function (error) {
				toast.error(error?.response?.data?.message || error?.message)
			});
	},

	// Check User Session in storage
	async checkUserSession() {
		return (await localStorage.getItem("userAuthDetails")) !== null;
	},

	// Get User profile from storage
	async getUserProfile() {
		let userObject = {};
		const userDetails = JSON.parse(localStorage.getItem("userAuthDetails"));

		if (localStorage.getItem("userAuthDetails") !== null) {
			return await axios
				.get(env.API_URL + `user/${userDetails._id}`, {
				})
				.then(function (response) {
					response.flag = parseInt(response.status) === 201;
					userObject = {...response.data.user};
					if (response.flag) {
						userObject.isExpired = false;
					}
					
					return userObject;
				})
				.catch(function (error) {
					userObject.isExpired= true;
					return userObject;
				});
		} else {
			userObject["isExpired"] = true;
			return await userObject;
		}
	},

	// Destroy User Session
	async userLogout() {
		await axios
			.get(env.API_URL + "user/logout",
				{
					headers: {
						'Authorization': `Bearer ${token}`
					}
				}
			)
			.then(function (response) {
				toast.success(response.data?.message)
			})
			.catch(function (error) {
				return error.response?.data;
			});
		localStorage.clear();
		return true;
	},

	//User Registration
	async userRegistration(data) {
		return await axios
			.post(env.API_URL + "user/signup", data)
			.then(function (response) {
				console.log(response)
				if (parseInt(response.status) === 200) {
					response.flag = true;
				}
				return response;
			})
			.catch(function (error) {
				return error.response.data;
			});
	},

	// Checking User Activation
	async userVerification(data) {
		return await axios
			.get(env.API_URL + `auth/email-confirmation?confirmation=${data}`)
			.then(function (response) {
				if (parseInt(response.status) === 200) {
					response.flag = true;
				}
				return response;
			})
			.catch(function (error) {
				return error.response.data;
			});
	},

	// Resend User Activation
	async resendUserActivation(data) {
		return await axios
			.post(env.API_URL + "auth/send-email-confirmation", data)
			.then(function (response) {
				if (parseInt(response.status) === 200) {
					response.flag = true;
				}
				return response;
			})
			.catch(function (error) {
				return error.response.data;
			});
	},

	//Forgot Password Update
	async resetPasswordUpdate(data) {
		return await axios
			.post(env.API_URL + "user/forgot-password", data)
			.then(function (response) {
				if (parseInt(response.status) === 200) {
					response.flag = true;
				}
				return response;
			})
			.catch(function (error) {
				return error?.response?.data;
			});
	},

	//Get single user details
	async getSingleUser(data, token = null) {
		return await axios
			.post(env.API_URL + "userDetails?_format=json", data, {
				headers: token
					? {
							Authorization: `Bearer ${token}`,
					  }
					: {},
			})
			.then(function (response) {
				if (
					parseInt(response.data.code) === 200 &&
					response.data.status === "success"
				) {
					response.flag = true;
				}
				return response;
			})
			.catch(function (error) {
				return error;
			});
	},

	//update profile image
	async updateImage(data) {
		return await axios
			.put(env.API_URL + "updateImage?_format=json", data, {
				headers: JSON.parse(localStorage.getItem("userAuthDetails"))
					.data.ACCESS_TOKEN
					? {
							Authorization: `Bearer ${
								JSON.parse(
									localStorage.getItem("userAuthDetails")
								).data.ACCESS_TOKEN
							}`,
					  }
					: {},
			})
			.then(function (response) {
				if (
					parseInt(response.data.code) === 200 &&
					response.data.status === "success"
				) {
					response.flag = true;
				}
				return response;
			})
			.catch(function (error) {
				return error;
			});
	},
	async updateProfile(data) {
		const userId = JSON.parse(localStorage.getItem("userAuthDetails")).user
			.id;
		return await axios
			.put(env.API_URL + `users/${userId}`, data, {
				headers: localStorage.getItem("userAuthDetails")
					? {
							Authorization: `Bearer ${
								JSON.parse(
									localStorage.getItem("userAuthDetails")
								).jwt
							}`,
					  }
					: {},
			})
			.then(function (response) {
				if (parseInt(response.status) === 200) {
					response.flag = true;
				}
				return response;
			})
			.catch(function (error) {
				return error;
			});
	},

	async updatePass(data) {
		return await axios
			.post(env.API_URL + "user/reset-password", data)
			.then(function (response) {
				if (parseInt(response.status) === 200) {
					response.flag = true;
				}
				return response;
			})
			.catch(function (error) {
				return error.response.data.data[0].messages[0];
			});
	},

	async createFileUpload(data) {
		return await axios
			.post(env.API_URL + "upload", data, {
				headers: {
					Authorization: `Bearer ${
						JSON.parse(localStorage.getItem("userAuthDetails")).jwt
					}`,
				},
			})
			.then(function (response) {
				const imageId = response.data[0].id;
				response.flag = parseInt(response.status) === 200 || 201;
				axios
					.post(env.API_URL + "users", { image: imageId })
					.then((res) => {
						return response;
					});
			})
			.catch(function (error) {
				return error;
			});
	},
};

export default AuthService;
